<template>
  <div class="dialer-settings-main dialer-schedule-main v2">
    <section class="dialer-settings-section w-100 mw-100">
      <div class="dialer-settings-section-inner">

        <div class="dialer-flex">
          <h2 class="dialer-settings-title mb-0">Members</h2>
          <div :class="`w-25 h-50px d-flex align-items-center justify-content-end ${selected.tab == 'favourites' ? 'justify-content-end' : ''}`">
            <div class="mr-12px cursor_pointer_no_hover compactViewContainer" @click="gridView=!gridView;">
              <vb-icon v-if="gridView" icon="members-gridView-icon" height="50px" width="50px" />
              <vb-icon v-else icon="todo-compact-icon" height="50px" width="50px" />
            </div>
            <b-form v-if="selected.tab == 'users'" class="whiteBGinputWithGreyRoundedBorder w-100" @submit.prevent="''">
              <b-input v-model="filter.users.search" placeholder="Search here" class="w-100 mx-1" />
            </b-form>
            <b-form v-else-if="selected.tab == 'teams'" class="whiteBGinputWithGreyRoundedBorder w-100" @submit.prevent="''">
              <b-input v-model="filter.teams.search" placeholder="Search here" class="w-100 mx-1" />
            </b-form>
            <b-form v-else-if="selected.tab == 'queue'" class="whiteBGinputWithGreyRoundedBorder w-100" @submit.prevent="''">
              <b-input v-model="filter.call_queue.search" placeholder="Search here" class="w-100 mx-1" />
            </b-form>
            <template v-else-if="selected.tab == 'addressbook'">
              <template v-if="selected.subTab=='my'">
                <b-form class="whiteBGinputWithGreyRoundedBorder w-100" @submit.prevent="''">
                  <b-input v-model="filter.local_addressbooks.search" placeholder="Search my contacts" class="w-100 mx-1" />
                </b-form>
              </template>
              <template v-if="selected.subTab=='global'">
                <b-form class="whiteBGinputWithGreyRoundedBorder w-100" @submit.prevent="''">
                  <b-input v-model="filter.global_addressbooks.search" placeholder="Search global contacts" class="w-100 mx-1" />
                </b-form>
              </template>
            </template>
          </div>
        </div>
        <div class="w-100 d-flex align-items-center justify-content-between mt-20px">
          <div class="TabsDesignWithIcon-container">
            <div class="TabsDesignWithIcon-item" v-for="allTab in tabs" :key="allTab.id" @click="selected.tab = allTab.value" :class="{'active': selected.tab == allTab.value,'mwd-168px' : allTab.value == 'balance'}">
              <vb-icon :icon="allTab.icon" height="38px" width="38px" />
              <span class="TabsDesignWithIcon-item-textPart">{{allTab.text}}</span>
            </div>
          </div>
          <div class="TabsDesignWithIcon-item mwd-168px" @click="selected.tab = 'addressbook'" :class="{'active': selected.tab == 'addressbook'}">
            <vb-icon icon="newbilling-tab-invoices-icon" height="38px" width="38px" />
            <span class="TabsDesignWithIcon-item-textPart">Address book</span>
          </div>
        </div>
        <!-- <div class="latestTabsDesign mt-38px">
          <div class="latestTabsDesign-item" :id="`popover-1-${allTab.value}`" v-for="allTab in tabs" :key="allTab.id" @click="selected.tab = allTab.value" :class="{'active': selected.tab == allTab.value}">
            {{allTab.text}}
          </div>
          <div class="latestTabsDesign-item ml-auto"  @click="selected.tab = 'addressbook'" :class="{'active': selected.tab == 'addressbook'}">
            Address book
          </div>
        </div> -->

        <template v-if="selected.tab == 'users'">
          <!-- <div class="w-100 d-flex justify-content-end mt-16px">
            <b-form class="whiteBGinputWithGreyRoundedBorder w-25" @submit.prevent="''">
              <b-input v-model="filter.users.search" placeholder="Search here" class="w-100 mx-1" />
            </b-form>
          </div> -->
          <template v-if="gridView">
            <div class="settingsGridView-container mt-20px">
              <div class="settingsGridView-item" v-for="data in searchedUsers" :key="data.voipaccount">
                <!-- v-show="$store.getters.getCurrentUser.account != data.voipaccount" -->
                <!-- <vb-avatar 
                  v-if="data.voipaccount" 
                  :account="data.voipaccount" 
                  :image="data.profile_img" 
                  :name="data | get_property('user.display_name')" 
                  :status_id="data | get_property('status')"  
                /> -->
                <Info :id="data.voipaccount" class="settingsGridView-item-info-component" :devices="true" :is_unread_show="true" :is_blf="false" />
                <!-- user -->
                <!-- <div v-if="data.voipaccount" class="settingsGridView-display_name">{{ data | get_property('user.display_name') }}</div> -->
                <!-- invite -->
                <div v-if="data.object=='UserInvitation'" class="settingsGridView-display_name">
                  {{ data | get_property('data.firstname') }} {{ data | get_property('data.lastname') }}
                </div>
                
                <div class="settingsGridView-email">
                  {{ data | get_property('user.job_tittle') }}
                </div>
                
                <!-- user -->
                <div v-if="data.voipaccount && $store.getters.getCurrentUser.account != data.voipaccount" class="d-flex settingsGridView-bottomIcons-container mt-20px">
                  <div class="mr-3 cursor_pointer_no_hover" v-b-popover.hover.top="`Pick call`" @click="!getUserPermissions.blf?appNotify.open({ message: 'Your package does not support this feature', type: 'danger' }):dialinringing(data)" v-if="data.state=='Ringing'">
                    <vb-icon icon="newScheduling-call-icon" height="55px" width="55px" /> 
                  </div>
                  <div class="mr-3 cursor_pointer_no_hover" v-b-popover.hover.top="`Call`" @click="dial(data)" v-else>
                    <vb-icon icon="newScheduling-call-icon" height="55px" width="55px" /> 
                  </div>
                  <div class="mr-3 cursor_pointer_no_hover" :id="`RightSideUsersChatOption-${data.voipaccount}`" 
                    v-b-tooltip :title="getUserPermissions.chat?'':'Your package does not support this feature'"
                    :class="['' , !getUserPermissions.chat ? 'for-disable' : '']"
                  >
                    <div v-b-popover.hover.top="`Chat`" :class="!getUserPermissions.chat ? 'for-disable-disable' : ''"  @click="!getUserPermissions.chat ? '' :chatUser(data)">
                      <vb-icon icon="newScheduling-chat-icon" height="55px" width="55px" />  
                    </div>
                  </div>
                  <div class="mr-3 cursor_pointer_no_hover" v-b-popover.hover.top="`Video call`" @click="videoCall(data)">
                    <vb-icon icon="newScheduling-video-icon" height="55px" width="55px" />  
                  </div>
                  <div class="cursor_pointer_no_hover" v-b-popover.hover.top="`${data.is_favourites ? 'Remove from favourite' : 'Add to favourite'}`" @click="toggleUserFavourite(data)">
                    <vb-icon :icon="data.is_favourites ? 'newScheduling-favourite-icon' : 'newScheduling-notfavourite-icon'" height="55px" width="55px" /> 
                  </div>
                </div>

              </div>
            </div>
          </template>
          <template v-else>
            <vb-table 
              textNoRecord=" " 
              id="users-table" 
              class="latestTableDesign-withBlackBorders-again showGraphIconOnlyOnHover" 
              :isListEmpty="isEmpty(searchedUsers)" 
              :listLength="searchedUsers.length" 
              :perPage="5" 
              :conditions="{ 
                loading: { 
                  show: false 
                }, 
                pagination: { 
                  show: false 
                }, 
              }"
            >
              <template slot="header">
                <tr>
                  <th class="dialer-has-sort">
                    <span>Name</span>
                  </th>
                  <th class="dialer-has-sort dialer-col-right"></th>
                </tr>
              </template>
              <template #body>
                <tr 
                  class="dialer-row-select" 
                  v-for="data in searchedUsers" 
                  :key="data.id || data.voipaccount" 
                >
                  <td class="dialer-row-title one w-100">
                    <div class="w-100 d-flex align-items-center justify-content-between">
                      <div class="d-flex align-items-center">
                        <!-- user -->
                        <Info class="w-fit-content"
                          v-if="data.voipaccount" 
                          is_suspended_show 
                          :id="data.voipaccount" 
                          :is_blf="false" 
                          :username="data | usernameDisplay($store.getters.getCurrentUser.email)" 
                        />
                        <!-- invite -->
                        <template v-if="data.object=='UserInvitation'">
                          <Info class="w-fit-content" v-if="data.object=='UserInvitation'" :name="`${data.data.firstname} ${data.data.lastname}`" :username="`${data.email}`">
                            <template #sub-info>
                              <span class="verySmallText">
                                {{ data | invitationExpireText }}
                                <b-icon
                                  class="cursor_pointer_no_hover ml-2"
                                  icon="arrow-clockwise"
                                  variant="dark"
                                  :animation="api.retry_invitation.send.includes(data.id)?'spin':''"
                                  @click="retryInvitation(data)"
                                />
                                <b-icon
                                  class="cursor_pointer_no_hover ml-2"
                                  icon="trash-fill"
                                  variant="danger"
                                  :animation="api.cancel_invitation.send.includes(data.id)?'spin':''"
                                  @click="cancelInvitation(data)"
                                />
                              </span>
                            </template>
                          </Info>
                        </template>
                        <!-- queue service -->
                        <Info class="w-fit-content" v-if="data.queue_service" :id="data.id" />

                        <template v-if="data.voipaccount">
                          <span v-if="data.user.user_type == 'main'" class="OwnertaggedVersion mb-0 ml-16px">Owner</span>
                          <span v-else-if="data.user.userRole != 0" class="AdmintaggedVersion mb-0 ml-16px">Admin</span>
                        </template>
                      </div>
                      <div class="d-flex align-items-center">
                        <vb-icon 
                          v-for="(device, accountcode) in data.devices" 
                          :key="accountcode" 
                          :class="`mr-12px statusWithRespectToDevice`" 
                          :icon="device.type | accountType" 
                          width="20.219px" 
                          height="24.997px" 
                        />
                      </div>
                    </div>
                  </td>
                  <td class="dialer-col-right four">
                    <div v-if="data.voipaccount && $store.getters.getCurrentUser.account != data.voipaccount" class="d-flex justify-content-end settingsGridView-bottomIcons-container showOnly-OnHover-insideTable">
                      <div class="mr-3 cursor_pointer_no_hover" v-b-popover.hover.top="`Pick call`" @click="!getUserPermissions.blf?appNotify.open({ message: 'Your package does not support this feature', type: 'danger' }):dialinringing(data)" v-if="data.state=='Ringing'">
                        <vb-icon icon="newScheduling-call-icon" height="35px" width="35px" /> 
                      </div>
                      <div class="mr-3 cursor_pointer_no_hover" v-b-popover.hover.top="`Call`" @click="dial(data)" v-else>
                        <vb-icon icon="newScheduling-call-icon" height="35px" width="35px" /> 
                      </div>
                      <div class="mr-3 cursor_pointer_no_hover" :id="`RightSideUsersChatOption-${data.voipaccount}`" 
                        v-b-tooltip :title="getUserPermissions.chat?'':'Your package does not support this feature'"
                        :class="['' , !getUserPermissions.chat ? 'for-disable' : '']"
                      >
                        <div v-b-popover.hover.top="`Chat`" :class="!getUserPermissions.chat ? 'for-disable-disable' : ''"  @click="!getUserPermissions.chat ? '' :chatUser(data)">
                          <vb-icon icon="newScheduling-chat-icon" height="35px" width="35px" />  
                        </div>
                      </div>
                      <div class="mr-3 cursor_pointer_no_hover" v-b-popover.hover.top="`Video call`" @click="videoCall(data)">
                        <vb-icon icon="newScheduling-video-icon" height="35px" width="35px" />  
                      </div>
                      <div class="cursor_pointer_no_hover" v-b-popover.hover.top="`${data.is_favourites ? 'Remove from favourite' : 'Add to favourite'}`" @click="toggleUserFavourite(data)">
                        <vb-icon :icon="data.is_favourites ? 'newScheduling-favourite-icon' : 'newScheduling-notfavourite-icon'" height="35px" width="35px" /> 
                      </div>
                    </div>
                  </td>
                </tr>
              </template>
            </vb-table>
          </template>
        </template>
        
        <template v-else-if="selected.tab == 'teams'">
          <!-- <div class="w-100 d-flex justify-content-end mt-16px">
            <b-form class="whiteBGinputWithGreyRoundedBorder w-25" @submit.prevent="''">
              <b-input v-model="filter.teams.search" placeholder="Search here" class="w-100 mx-1" />
            </b-form>
          </div> -->
          <template v-if="gridView">
            <div class="settingsGridView-container mt-32px">
              <div class="settingsGridView-item"  v-for="team in filterTeams" :key="team.id">
                <vb-avatar :id="team.real_id" :is_blf="false" />
                <div class="settingsGridView-display_name">{{team.name}}</div>
                <div class="d-flex settingsGridView-bottomIcons-container mt-20px">
                  <div class="mr-3 cursor_pointer_no_hover" v-b-popover.hover.top="`Call`" @click="dialTeam(team)">
                    <vb-icon icon="newScheduling-call-icon" height="55px" width="55px" /> 
                  </div>
                  <div class="mr-3 cursor_pointer_no_hover" v-if="team.members.findIndex(mem=>mem.accountcode==getCurrentUser.account)>-1" @click="chatTeam(team)">
                    <div v-b-popover.hover.top="`Chat`" @click="chatTeam(team)">
                      <vb-icon icon="newScheduling-chat-icon" height="55px" width="55px" />  
                    </div>
                  </div>
                  <div class="cursor_pointer_no_hover" v-b-popover.hover.top="`${team.is_favourite ? 'Remove from favourite' : 'Add to favourite'}`" @click="toggleFavourite(team)">
                    <vb-icon :icon="team.is_favourite ? 'newScheduling-favourite-icon' : 'newScheduling-notfavourite-icon'" height="55px" width="55px" /> 
                  </div>
                </div>
                <div class="d-flex align-items-center justify-content-between w-100" v-b-toggle="'accordion-' + team.id">
                  <div class="settingsGridView-email mt-2 mb-0">
                    {{team.members.length}} members
                  </div>
                  <b-icon icon="chevron-down"/>
                </div>
                <b-collapse class="settingsGridView-collapseable-container" :id="'accordion-' + team.id" accordion="my-accordion">
                  <div v-if="!isEmpty(team.members)">
                    <div class="settingsGridView-collapseable-row" v-for="(member, key) in team.members" :key="key">
                      <div class="settingsGridView-collapseable-row-leftSide">
                        <Info :id="member.accountcode" :is_blf="false" />
                      </div>
                      <div class="settingsGridView-collapseable-row-rightSide">
                        <span class="whiteBGinputWithGreyRoundedBorder dropdownVersion filterDD btnMute">
                          <b-dropdown v-if="getCurrentUser.account!=member.accountcode" varient="link" no-caret right class="ico-filter">
                            <template v-slot:button-content>
                              <b-icon icon="three-dots-vertical" scale="1.5" />
                            </template>
                            <b-dropdown-item @click="dial(member)">
                              <vb-svg class="mr-2" name="dialer-outlineCall-icon2" width="16.501" height="16.501" viewBox="0 0 16.501 16.501" stroke-width="0" stroke="#005DFF" fill="none" stroke-linecap="round" stroke-linejoin="round" />
                              <span>Call</span>
                            </b-dropdown-item>
                            <b-dropdown-item @click="chatUser(member)">
                              <vb-svg class="mr-2" name="dialer-outlineChat-icon" width="16.501" height="16.501" viewBox="0 0 16.501 16.501" stroke-width="0" stroke="#005DFF" fill="none" stroke-linecap="round" stroke-linejoin="round" />
                              <span>Chat</span>
                            </b-dropdown-item>
                            <b-dropdown-item @click="videoCall(member)">
                              <vb-svg class="mr-2" name="dialer-outlineVideo-icon" width="20.6" height="13.6" viewBox="0 0 20.6 13.6" stroke-width="0" stroke="#005DFF" fill="none" stroke-linecap="round" stroke-linejoin="round" />
                              <span>Video</span>
                            </b-dropdown-item>
                          </b-dropdown>
                        </span>
                      </div>
                    </div>
                  </div>
                  <vb-no-record v-if="isEmpty(team.members)" :text="'No members in this Group'" :design="3" />
                </b-collapse>
              </div>
              <vb-no-record v-if="isEmpty(filterTeams)" :text="'No teams available'" :design="3" />
            </div>
          </template>
          <template v-else>
            <vb-table textNoRecord=" " class="latestTableDesign-withBlackBorders-again showGraphIconOnlyOnHover" id="teams-table" :isListEmpty="isEmpty(filterTeams)" :listLength="filterTeams.length" :perPage="5" :noRecordWholePage="true">
              <template slot="header">
                <tr>
                  <th class="dialer-has-sort">
                    <span class="dialer-sorted-by ascending">Name</span>
                  </th>
                  <th class="dialer-has-sort">
                    <span>Members</span>
                  </th>
                  <th class="dialer-has-sort dialer-col-right"></th>
                </tr>
              </template>
              <template #body="{ start, end }">
                <template v-for="(team, index) in filterTeams">
                  <tr 
                    :class="`dialer-row-select ${conditions.more==team.id ? 'makeTDverticalAlignTop' : ''}`" 
                    :key="team.id" 
                    v-show="index >= start && index <= end" 
                  >
                    <td class="dialer-row-title one">
                      <Info class="w-fit-content" v-if="team.queue_service" :id="team.id" :is_blf="false" />
                      <Info class="w-fit-content" v-else :id="team.real_id" :is_blf="false" is_suspended_show />
                    </td>
                    <td class="dialer-row-title three">
                      <div v-if="!team.queue_service" class="d-flex">
                        <a class="dialer-link-more text-decoration-underline" @click="membersModalData.type='team';membersModalData.items = team.members;$modal.show('ShowMembersOnlyModal')">
                          {{ team.members.length }} users
                        </a>
                      </div>
                    </td>
                    <td class="dialer-col-right four">
                      <div class="d-flex justify-content-end settingsGridView-bottomIcons-container showOnly-OnHover-insideTable">
                        <div class="mr-3 cursor_pointer_no_hover" v-b-popover.hover.top="`Call`" @click="dialTeam(team)">
                          <vb-icon icon="newScheduling-call-icon" height="35px" width="35px" /> 
                        </div>
                        <div class="mr-3 cursor_pointer_no_hover" v-if="team.members.findIndex(mem=>mem.accountcode==getCurrentUser.account)>-1" @click="chatTeam(team)">
                          <div v-b-popover.hover.top="`Chat`" @click="chatTeam(team)">
                            <vb-icon icon="newScheduling-chat-icon" height="35px" width="35px" />  
                          </div>
                        </div>
                        <div class="cursor_pointer_no_hover" v-b-popover.hover.top="`${team.is_favourite ? 'Remove from favourite' : 'Add to favourite'}`" @click="toggleFavourite(team)">
                          <vb-icon :icon="team.is_favourite ? 'newScheduling-favourite-icon' : 'newScheduling-notfavourite-icon'" height="35px" width="35px" /> 
                        </div>
                      </div>
                    </td>
                  </tr>
                </template>
              </template>
              <template #loading>
                <tr v-for="n in 5" :key="n">
                  <td>
                    <div class="d-flex">
                      <div class="latestShimmerDesign mr-2" style="height:35px;width:35px;"></div>
                      <div class="d-flex flex-column justify-content-between">
                        <div class="latestShimmerDesign flex-1" style="height:16px;width:60px"></div>
                        <div class="latestShimmerDesign mt-1" style="height:16px;width:30px"></div>
                      </div>
                    </div>
                  </td>
                  <td>
                    <div class="d-flex">
                      <div class="latestShimmerDesign mr-2" style="height:20px;min-width:40px;"></div>
                      <div class="latestShimmerDesign" style="height:20px;width:-webkit-fill-available;"></div>
                    </div>
                  </td>
                  <td>
                    <div class="latestShimmerDesign" style="height:20px;width:-webkit-fill-available;"></div>
                  </td>
                  <td class="dialer-col-right">
                    <div class="latestShimmerDesign ml-auto" style="height:48px;width:48px;"></div>
                  </td>
                </tr>
              </template>
              <template #no-record>
                <div class="latestGreyBox-9-9-2023 mb-32px">
                  <div class="emptyScreenContainer">
                    <img width="365px" height="128px" class="mt-50px" v-if="GET_LOCAL_SETTING_IS_DARK_MODE" :src="require('@/assets/images/emptyScreenImages/darkMode/teams.png')"/>
                    <img width="365px" height="128px" class="mt-50px" v-else :src="require('@/assets/images/emptyScreenImages/teams.png')"/>
                    <div class="emptyScreenContainer-heading mt-50px">
                      Create your first team
                    </div>
                    <div class="emptyScreenContainer-text w-75">
                      Teams help you organize and collaborate efficiently. Create your first team to get started.
                    </div>
                    <button @click="$modal.show('AddTeamModal',{ suggested_name: `team ${voip_teams.length+1}` })" class="dialer-button dialer-button-primary mt-20px">
                      <b-icon icon="plus-lg" class="mr-3" />
                      Create team
                    </button>
                  </div>
                </div>
              </template>
            </vb-table> 
          </template>
        </template>
        
        <template v-else-if="selected.tab == 'favourites'">
          <!-- <div class="w-100 d-flex justify-content-end mt-16px"> -->
            <!--<b-form class="whiteBGinputWithGreyRoundedBorder w-25" @submit.prevent="''">
              <!~~ <b-input placeholder="Search here" class="w-100 mx-1" /> ~~>
            </b-form>-->
          <!-- </div> -->
          <template v-if="gridView">
            <div class="settingsGridView-container mt-20px">
              <div class="settingsGridView-item" v-for="team in favouriteTeams" :key="team.id">
                <vb-avatar :image="team.avatar.image.image"  />
                <div class="settingsGridView-display_name">{{team.name}}</div>
                <div class="d-flex settingsGridView-bottomIcons-container mt-20px">
                  <div class="mr-3 cursor_pointer_no_hover" v-b-popover.hover.top="`Call`" @click="dialTeam(team)">
                    <vb-icon icon="newScheduling-call-icon" height="55px" width="55px" /> 
                  </div>
                  <div class="mr-3 cursor_pointer_no_hover" v-if="team.members.findIndex(mem=>mem.accountcode==getCurrentUser.account)>-1" @click="chatTeam(team)">
                    <div v-b-popover.hover.top="`Chat`" @click="chatTeam(team)">
                      <vb-icon icon="newScheduling-chat-icon" height="55px" width="55px" />  
                    </div>
                  </div>
                  <div class="cursor_pointer_no_hover" v-b-popover.hover.top="`${team.is_favourite ? 'Remove from favourite' : 'Add to favourite'}`" @click="toggleFavourite(team)">
                    <vb-icon :icon="team.is_favourites ? 'newScheduling-favourite-icon' : 'newScheduling-notfavourite-icon'" height="55px" width="55px" /> 
                  </div>
                </div>
                <div class="d-flex align-items-center justify-content-between w-100" v-b-toggle="'accordionF-' + team.id">
                  <div class="settingsGridView-email mt-2 mb-0">
                    {{team.members.length}} members
                  </div>
                  <b-icon icon="chevron-down"/>
                </div>
                <b-collapse class="settingsGridView-collapseable-container" :id="'accordionF-' + team.id" accordion="my-accordion">
                  <div v-if="!isEmpty(team.members)">
                    <div class="settingsGridView-collapseable-row" v-for="(member, key) in team.members" :key="key">
                      <div class="settingsGridView-collapseable-row-leftSide">
                        <Info :id="member.accountcode" :is_blf="false" />
                      </div>
                      <div class="settingsGridView-collapseable-row-rightSide">
                        <span class="whiteBGinputWithGreyRoundedBorder dropdownVersion filterDD btnMute">
                          <b-dropdown v-if="getCurrentUser.account!=member.accountcode" varient="link" no-caret right class="ico-filter">
                            <template v-slot:button-content>
                              <b-icon icon="three-dots-vertical" scale="1.5" />
                            </template>
                            <b-dropdown-item @click="dial(member)">
                              <vb-svg class="mr-2" name="dialer-outlineCall-icon2" width="16.501" height="16.501" viewBox="0 0 16.501 16.501" stroke-width="0" stroke="#005DFF" fill="none" stroke-linecap="round" stroke-linejoin="round" />
                              <span>Call</span>
                            </b-dropdown-item>
                            <b-dropdown-item @click="chatUser(member)">
                              <vb-svg class="mr-2" name="dialer-outlineChat-icon" width="16.501" height="16.501" viewBox="0 0 16.501 16.501" stroke-width="0" stroke="#005DFF" fill="none" stroke-linecap="round" stroke-linejoin="round" />
                              <span>Chat</span>
                            </b-dropdown-item>
                            <b-dropdown-item @click="videoCall(member)">
                              <vb-svg class="mr-2" name="dialer-outlineVideo-icon" width="20.6" height="13.6" viewBox="0 0 20.6 13.6" stroke-width="0" stroke="#005DFF" fill="none" stroke-linecap="round" stroke-linejoin="round" />
                              <span>Video</span>
                            </b-dropdown-item>
                          </b-dropdown>
                        </span>
                      </div>
                    </div>
                  </div>
                  <vb-no-record v-if="isEmpty(team.members)" :text="'No members in this Group'" :design="3" />
                </b-collapse>
              </div>
              <div class="settingsGridView-item" v-for="data in favouriteUsers" :key="data.voipaccount">
                <vb-avatar 
                  v-if="data.voipaccount" 
                  :account="data.voipaccount" 
                  :image="data.profile_img" 
                  :name="data | get_property('user.display_name')" 
                  :status_id="data | get_property('status')"  
                />
                <!-- user -->
                <div v-if="data.voipaccount" class="settingsGridView-display_name">{{ data | get_property('user.display_name') }}</div>
                <!-- invite -->
                <div v-if="data.object=='UserInvitation'" class="settingsGridView-display_name">
                  {{ data | get_property('data.firstname') }} {{ data | get_property('data.lastname') }}
                </div>
                
                <div class="settingsGridView-email">
                  Job title
                </div>
                
                <!-- user -->
                <div v-if="data.voipaccount" class="d-flex settingsGridView-bottomIcons-container mt-20px">
                  <div class="mr-3 cursor_pointer_no_hover" v-b-popover.hover.top="`Pick call`" @click="!getUserPermissions.blf?appNotify.open({ message: 'Your package does not support this feature', type: 'danger' }):dialinringing(data)" v-if="data.state=='Ringing'">
                    <b-icon icon="telephone-fill" />
                  </div>
                  <div class="mr-3 cursor_pointer_no_hover" v-b-popover.hover.top="`Call`" @click="dial(data)" v-else>
                    <vb-icon icon="newScheduling-call-icon" height="55px" width="55px" /> 
                  </div>
                  <div class="mr-3 cursor_pointer_no_hover" :id="`RightSideUsersChatOption-${data.voipaccount}`" 
                    v-b-tooltip :title="getUserPermissions.chat?'':'Your package does not support this feature'"
                    :class="['' , !getUserPermissions.chat ? 'for-disable' : '']"
                  >
                    <div v-b-popover.hover.top="`Chat`" :class="!getUserPermissions.chat ? 'for-disable-disable' : ''"  @click="!getUserPermissions.chat ? '' :chatUser(data)">
                      <vb-icon icon="newScheduling-chat-icon" height="55px" width="55px" />  
                    </div>
                  </div>
                  <div class="mr-3 cursor_pointer_no_hover" v-b-popover.hover.top="`Video call`" @click="videoCall(data)">
                    <vb-icon icon="newScheduling-video-icon" height="55px" width="55px" />  
                  </div>
                  <div class="cursor_pointer_no_hover" v-b-popover.hover.top="`${data.is_favourites ? 'Remove from favourite' : 'Add to favourite'}`" @click="toggleUserFavourite(data)">
                    <vb-icon :icon="data.is_favourites ? 'newScheduling-favourite-icon' : 'newScheduling-notfavourite-icon'" height="55px" width="55px" /> 
                  </div>
                </div>
              </div>
              <div class="settingsGridView-item"  v-for="call_queue in favouriteCallQueue" :key="call_queue.voipaccount">
                <vb-avatar
                  :account="call_queue.voipaccount" 
                  :image="call_queue.avatar.image.image" 
                  :name="call_queue | get_property('user.display_name')" 
                  :status_id="call_queue | get_property('status')" 
                />
                <div class="settingsGridView-display_name">{{call_queue.label}}</div>
                <div class="d-flex settingsGridView-bottomIcons-container mt-20px">
                  <div class="mr-3 cursor_pointer_no_hover" v-b-popover.hover.top="`Call`" @click="dialCallQueue(call_queue)">
                    <vb-icon icon="newScheduling-call-icon" height="55px" width="55px" /> 
                  </div>
                  <template v-if="getProperty(call_queue,`call_queue_status.${getCurrentUser.account}.adminforce`,'no')=='no'">
                    <div class="cursor_pointer_no_hover" v-b-popover.hover.top="`${call_queue.is_favourite ? 'Remove from favourite' : 'Add to favourite'}`" @click="toggleCallQueueFavourite(call_queue)">
                      <vb-icon :icon="call_queue.is_favourite ? 'newScheduling-favourite-icon' : 'newScheduling-notfavourite-icon'" height="55px" width="55px" /> 
                    </div>
                  </template>
                </div>
                <div class="d-flex align-items-center justify-content-between w-100" v-b-toggle="'accordion-' + call_queue.voipaccount">
                  <div class="settingsGridView-email mt-2 mb-0">
                    {{call_queue.members.length}} members
                  </div>
                  <b-icon icon="chevron-down"/>
                </div>
                <b-collapse class="settingsGridView-collapseable-container" :id="'accordion-' + call_queue.voipaccount" accordion="my-accordion">
                  <div v-if="!isEmpty(call_queue.members)">
                    <div class="settingsGridView-collapseable-row" v-for="member in call_queue.members" :key="member.membername">
                      <div class="settingsGridView-collapseable-row-leftSide">
                        <Info :id="member.membername" :is_blf="false" />
                      </div>
                      <div class="settingsGridView-collapseable-row-rightSide">
                        <span class="whiteBGinputWithGreyRoundedBorder dropdownVersion filterDD btnMute">
                          <b-dropdown v-if="getCurrentUser.account!=member.membername" varient="link" no-caret right class="ico-filter">
                            <template v-slot:button-content>
                              <b-icon icon="three-dots-vertical" scale="1.5" />
                            </template>
                            <b-dropdown-item @click="dial(member)">
                              <vb-svg class="mr-2" name="dialer-outlineCall-icon2" width="16.501" height="16.501" viewBox="0 0 16.501 16.501" stroke-width="0" stroke="#005DFF" fill="none" stroke-linecap="round" stroke-linejoin="round" />
                              <span>Call</span>
                            </b-dropdown-item>
                            <b-dropdown-item @click="chatUser(member)">
                              <vb-svg class="mr-2" name="dialer-outlineChat-icon" width="16.501" height="16.501" viewBox="0 0 16.501 16.501" stroke-width="0" stroke="#005DFF" fill="none" stroke-linecap="round" stroke-linejoin="round" />
                              <span>Chat</span>
                            </b-dropdown-item>
                            <b-dropdown-item @click="videoCall(member)">
                              <vb-svg class="mr-2" name="dialer-outlineVideo-icon" width="20.6" height="13.6" viewBox="0 0 20.6 13.6" stroke-width="0" stroke="#005DFF" fill="none" stroke-linecap="round" stroke-linejoin="round" />
                              <span>Video</span>
                            </b-dropdown-item>
                          </b-dropdown>
                        </span>
                      </div>
                    </div>
                  </div>
                  <vb-no-record v-if="isEmpty(call_queue.members)" :text="'No members in this Group'" :design="3" />
                </b-collapse>
              </div>
              <div class="settingsGridView-item"  v-for="addressbook in favouriteAddressBook" :key="addressbook.real_id">
                <vb-avatar :image="addressbook | get_property('profile_image')" />
                <div class="settingsGridView-display_name">{{`${addressbook.firstName} ${addressbook.lastName || ''}`}}</div>
                <div class="settingsGridView-email">{{ addressbook | get_property('main_phone.number') | number_formater }}</div>
                <div class="d-flex settingsGridView-bottomIcons-container mt-20px">
                  <div class="mr-3 cursor_pointer_no_hover" v-b-popover.hover.top="`Call`" @click="audioCall(addressbook)">
                    <vb-icon icon="newScheduling-call-icon" height="55px" width="55px" /> 
                  </div>
                  <div :disabled="api.toggle_favourite.send.includes(addressbook.real_id)" @click="toggleFavouriteAddressbook(addressbook)" class="cursor_pointer_no_hover" v-b-popover.hover.top="`${addressbook.is_favourite ? 'Remove from favourite' : 'Add to favourite'}`">
                    <vb-icon :icon="addressbook.is_favourite ? 'newScheduling-favourite-icon' : 'newScheduling-notfavourite-icon'" height="55px" width="55px" /> 
                  </div>
                </div>
              </div>
              <vb-no-record v-if="isEmpty(favouriteTeams) && isEmpty(favouriteUsers) && isEmpty(favouriteCallQueue) && isEmpty(favouriteAddressBook)" :text="'Nothing is set as favourite'" :design="3" />
            </div>
          </template>
          <template v-else>
            <vb-table 
              :isListEmpty="favouriteTeams.length==0 && favouriteUsers.length==0 && favouriteCallQueue.length==0 && favouriteAddressBook.length==0" 
              :listLength="favouriteTeams.length + favouriteUsers.length + favouriteCallQueue.length + favouriteAddressBook.length" 
              class="latestTableDesign-withBlackBorders-again showGraphIconOnlyOnHover"
            >
              <template slot="header">
                <tr>
                  <th class="dialer-has-sort">
                    <span class="dialer-sorted-by ascending">Label</span>
                  </th>
                  <th class="dialer-has-sort">
                    <span>Members</span>
                  </th>
                  <th class="dialer-has-sort dialer-col-right"></th>
                </tr>
              </template>
              <template #body="{ start, end }">
                <template v-for="(team, index) in favouriteTeams">
                  <tr 
                    :class="`dialer-row-select ${conditions.more==team.id ? 'makeTDverticalAlignTop' : ''}`" 
                    :key="team.id" 
                    v-show="index >= start && index <= end" 
                  >
                    <td class="dialer-row-title one">
                      <Info class="w-fit-content" v-if="team.queue_service" :id="team.id" :is_blf="false" />
                      <Info class="w-fit-content" v-else :id="team.real_id" :is_blf="false" is_suspended_show />
                    </td>
                    <td class="dialer-row-title three">
                      <div v-if="!team.queue_service" class="d-flex">
                        <a class="dialer-link-more text-decoration-underline" @click="membersModalData.type='team';membersModalData.items = team.members;$modal.show('ShowMembersOnlyModal')">
                          {{ team.members.length }} users
                        </a>
                      </div>
                    </td>
                    <td class="dialer-col-right four">
                      <div class="d-flex justify-content-end settingsGridView-bottomIcons-container showOnly-OnHover-insideTable">
                        <div class="mr-3 cursor_pointer_no_hover" v-b-popover.hover.top="`Call`" @click="dialTeam(team)">
                          <vb-icon icon="newScheduling-call-icon" height="35px" width="35px" /> 
                        </div>
                        <div class="mr-3 cursor_pointer_no_hover" v-if="team.members.findIndex(mem=>mem.accountcode==getCurrentUser.account)>-1" @click="chatTeam(team)">
                          <div v-b-popover.hover.top="`Chat`" @click="chatTeam(team)">
                            <vb-icon icon="newScheduling-chat-icon" height="35px" width="35px" />  
                          </div>
                        </div>
                        <div class="cursor_pointer_no_hover" v-b-popover.hover.top="`${team.is_favourite ? 'Remove from favourite' : 'Add to favourite'}`" @click="toggleFavourite(team)">
                          <vb-icon :icon="team.is_favourite ? 'newScheduling-favourite-icon' : 'newScheduling-notfavourite-icon'" height="35px" width="35px" /> 
                        </div>
                      </div>
                    </td>
                  </tr>
                </template>
                <tr 
                  class="dialer-row-select" 
                  v-for="data in favouriteUsers" 
                  :key="data.id || data.voipaccount" 
                >
                  <td class="dialer-row-title one">
                    <div class="d-flex align-items-center">
                      <!-- user -->
                      <Info class="w-fit-content"
                        v-if="data.voipaccount" 
                        is_suspended_show 
                        :id="data.voipaccount" 
                        :is_blf="false" 
                        :username="data | usernameDisplay($store.getters.getCurrentUser.email)" 
                      />
                      <!-- invite -->
                      <template v-if="data.object=='UserInvitation'">
                        <Info class="w-fit-content" v-if="data.object=='UserInvitation'" :name="`${data.data.firstname} ${data.data.lastname}`" :username="`${data.email}`">
                          <template #sub-info>
                            <span class="verySmallText">
                              {{ data | invitationExpireText }}
                              <b-icon
                                class="cursor_pointer_no_hover ml-2"
                                icon="arrow-clockwise"
                                variant="dark"
                                :animation="api.retry_invitation.send.includes(data.id)?'spin':''"
                                @click="retryInvitation(data)"
                              />
                              <b-icon
                                class="cursor_pointer_no_hover ml-2"
                                icon="trash-fill"
                                variant="danger"
                                :animation="api.cancel_invitation.send.includes(data.id)?'spin':''"
                                @click="cancelInvitation(data)"
                              />
                            </span>
                          </template>
                        </Info>
                      </template>
                      <!-- queue service -->
                      <Info class="w-fit-content" v-if="data.queue_service" :id="data.id" />

                      <template v-if="data.voipaccount">
                        <span v-if="data.user.user_type == 'main'" class="OwnertaggedVersion mb-0 ml-16px">Owner</span>
                        <span v-else-if="data.user.userRole != 0" class="AdmintaggedVersion mb-0 ml-16px">Admin</span>
                      </template>
                    </div>
                  </td>
                  <td class="dialer-row-title three"></td>
                  <td class="dialer-col-right four">
                    <div v-if="data.voipaccount && $store.getters.getCurrentUser.account != data.voipaccount" class="d-flex justify-content-end settingsGridView-bottomIcons-container showOnly-OnHover-insideTable">
                      <div class="mr-3 cursor_pointer_no_hover" v-b-popover.hover.top="`Pick call`" @click="!getUserPermissions.blf?appNotify.open({ message: 'Your package does not support this feature', type: 'danger' }):dialinringing(data)" v-if="data.state=='Ringing'">
                        <vb-icon icon="newScheduling-call-icon" height="35px" width="35px" /> 
                      </div>
                      <div class="mr-3 cursor_pointer_no_hover" v-b-popover.hover.top="`Call`" @click="dial(data)" v-else>
                        <vb-icon icon="newScheduling-call-icon" height="35px" width="35px" /> 
                      </div>
                      <div class="mr-3 cursor_pointer_no_hover" :id="`RightSideUsersChatOption-${data.voipaccount}`" 
                        v-b-tooltip :title="getUserPermissions.chat?'':'Your package does not support this feature'"
                        :class="['' , !getUserPermissions.chat ? 'for-disable' : '']"
                      >
                        <div v-b-popover.hover.top="`Chat`" :class="!getUserPermissions.chat ? 'for-disable-disable' : ''"  @click="!getUserPermissions.chat ? '' :chatUser(data)">
                          <vb-icon icon="newScheduling-chat-icon" height="35px" width="35px" />  
                        </div>
                      </div>
                      <div class="mr-3 cursor_pointer_no_hover" v-b-popover.hover.top="`Video call`" @click="videoCall(data)">
                        <vb-icon icon="newScheduling-video-icon" height="35px" width="35px" />  
                      </div>
                      <div class="cursor_pointer_no_hover" v-b-popover.hover.top="`${data.is_favourites ? 'Remove from favourite' : 'Add to favourite'}`" @click="toggleUserFavourite(data)">
                        <vb-icon :icon="data.is_favourites ? 'newScheduling-favourite-icon' : 'newScheduling-notfavourite-icon'" height="35px" width="35px" /> 
                      </div>
                    </div>
                  </td>
                </tr>
                <tr v-for="(callQueue, index ) in favouriteCallQueue"
                  :key="callQueue.voipaccount || callQueue.id"
                  v-show="index >= start && index <= end" 
                >
                  <td class="dialer-row-title one">
                    <Info class="w-fit-content" :is_suspended_show="!!callQueue.voipaccount" :id="callQueue.voipaccount || callQueue.id" />
                  </td>
                  <td class="dialer-row-title three" >
                    <div v-if="!callQueue.queue_service" class="d-flex">
                      <a class="dialer-link-more text-decoration-underline" @click="membersModalData.type='queue';membersModalData.items = callQueue.members;$modal.show('ShowMembersOnlyModal')">
                        {{ callQueue | get_property('members.length',0) }} users
                      </a>
                    </div>
                  </td>
                  <td class="dialer-row-title dialer-col-right three">
                    <div class="d-flex justify-content-end settingsGridView-bottomIcons-container showOnly-OnHover-insideTable">
                      <div class="mr-3 cursor_pointer_no_hover" v-b-popover.hover.top="`Call`" @click="dialCallQueue(callQueue)">
                        <vb-icon icon="newScheduling-call-icon" height="35px" width="35px" /> 
                      </div>
                      <template v-if="getProperty(callQueue,`call_queue_status.${getCurrentUser.account}.adminforce`,'no')=='no'">
                        <div class="cursor_pointer_no_hover" v-b-popover.hover.top="`${callQueue.is_favourite ? 'Remove from favourite' : 'Add to favourite'}`" @click="toggleCallQueueFavourite(callQueue)">
                          <vb-icon :icon="callQueue.is_favourite ? 'newScheduling-favourite-icon' : 'newScheduling-notfavourite-icon'" height="35px" width="35px" /> 
                        </div>
                      </template>
                    </div>
                  </td>
                </tr>
                <tr v-for="(addressbook,index) in favouriteAddressBook" :key="addressbook.real_id" v-show="index >= start && index <= end" >
                  <td class="dialer-row-title one">
                    <div class="d-flex align-items-center">
                      <vb-avatar :image="addressbook | get_property('profile_image')" />
                      <div class="ml-3">
                        <div class="settingsGridView-display_name">{{`${addressbook.firstName} ${addressbook.lastName || ''}`}}</div>
                        <div class="settingsGridView-email">{{ addressbook | get_property('main_phone.number') | number_formater }}</div>
                      </div>
                    </div>
                  </td>
                  <td class="dialer-row-title three" >
                    
                  </td>
                  <td class="dialer-row-title dialer-col-right four">
                    <div class="d-flex justify-content-end settingsGridView-bottomIcons-container showOnly-OnHover-insideTable">
                      <div class="mr-3 cursor_pointer_no_hover" v-b-popover.hover.top="`Call`" @click="audioCall(addressbook)">
                        <vb-icon icon="newScheduling-call-icon" height="35px" width="35px" /> 
                      </div>
                      <div :disabled="api.toggle_favourite.send.includes(addressbook.real_id)" @click="toggleFavouriteAddressbook(addressbook)" class="cursor_pointer_no_hover" v-b-popover.hover.top="`${addressbook.is_favourite ? 'Remove from favourite' : 'Add to favourite'}`">
                        <vb-icon :icon="addressbook.is_favourite ? 'newScheduling-favourite-icon' : 'newScheduling-notfavourite-icon'" height="35px" width="35px" /> 
                      </div>
                    </div>
                  </td>
                </tr>
              </template>
            </vb-table>
          </template>
        </template>

        <template v-else-if="selected.tab == 'queue'">
          <!-- <div class="w-100 d-flex justify-content-end mt-16px">
            <b-form class="whiteBGinputWithGreyRoundedBorder w-25" @submit.prevent="''">
              <b-input v-model="filter.call_queue.search" placeholder="Search here" class="w-100 mx-1" />
            </b-form>
          </div> -->
          <template v-if="gridView">
            <div class="settingsGridView-container mt-20px">
              <div class="settingsGridView-item"  v-for="call_queue in filterCallQueues" :key="call_queue.voipaccount">
                <vb-avatar
                  :account="call_queue.voipaccount" 
                  :image="call_queue.avatar.image.image" 
                  :name="call_queue | get_property('user.display_name')" 
                  :status_id="call_queue | get_property('status')" 
                />
                <div class="settingsGridView-display_name">{{call_queue.label}}</div>
                <div class="d-flex settingsGridView-bottomIcons-container mt-20px">
                  <div class="mr-3 cursor_pointer_no_hover" v-b-popover.hover.top="`Call`" @click="dialCallQueue(call_queue)">
                    <vb-icon icon="newScheduling-call-icon" height="55px" width="55px" /> 
                  </div>
                  <template v-if="getProperty(call_queue,`call_queue_status.${getCurrentUser.account}.adminforce`,'no')=='no'">
                    <div class="cursor_pointer_no_hover" v-b-popover.hover.top="`${call_queue.is_favourite ? 'Remove from favourite' : 'Add to favourite'}`" @click="toggleCallQueueFavourite(call_queue)">
                      <vb-icon :icon="call_queue.is_favourite ? 'newScheduling-favourite-icon' : 'newScheduling-notfavourite-icon'" height="55px" width="55px" /> 
                    </div>
                  </template>
                </div>
                <div class="d-flex align-items-center justify-content-between w-100" v-b-toggle="'accordion-' + call_queue.voipaccount">
                  <div class="settingsGridView-email mt-2 mb-0">
                    {{call_queue.members.length}} members
                  </div>
                  <b-icon icon="chevron-down"/>
                </div>
                <b-collapse class="settingsGridView-collapseable-container" :id="'accordion-' + call_queue.voipaccount" accordion="my-accordion">
                  <div v-if="!isEmpty(call_queue.members)">
                    <div class="settingsGridView-collapseable-row" v-for="member in call_queue.members" :key="member.membername">
                      <div class="settingsGridView-collapseable-row-leftSide">
                        <Info :id="member.membername" :is_blf="false" />
                      </div>
                      <div class="settingsGridView-collapseable-row-rightSide">
                        <span class="whiteBGinputWithGreyRoundedBorder dropdownVersion filterDD btnMute">
                          <b-dropdown v-if="getCurrentUser.account!=member.membername" varient="link" no-caret right class="ico-filter">
                            <template v-slot:button-content>
                              <b-icon icon="three-dots-vertical" scale="1.5" />
                            </template>
                            <b-dropdown-item @click="dial(member)">
                              <vb-svg class="mr-2" name="dialer-outlineCall-icon2" width="16.501" height="16.501" viewBox="0 0 16.501 16.501" stroke-width="0" stroke="#005DFF" fill="none" stroke-linecap="round" stroke-linejoin="round" />
                              <span>Call</span>
                            </b-dropdown-item>
                            <b-dropdown-item @click="chatUser(member)">
                              <vb-svg class="mr-2" name="dialer-outlineChat-icon" width="16.501" height="16.501" viewBox="0 0 16.501 16.501" stroke-width="0" stroke="#005DFF" fill="none" stroke-linecap="round" stroke-linejoin="round" />
                              <span>Chat</span>
                            </b-dropdown-item>
                            <b-dropdown-item @click="videoCall(member)">
                              <vb-svg class="mr-2" name="dialer-outlineVideo-icon" width="20.6" height="13.6" viewBox="0 0 20.6 13.6" stroke-width="0" stroke="#005DFF" fill="none" stroke-linecap="round" stroke-linejoin="round" />
                              <span>Video</span>
                            </b-dropdown-item>
                          </b-dropdown>
                        </span>
                      </div>
                    </div>
                  </div>
                  <vb-no-record v-if="isEmpty(call_queue.members)" :text="'No members in this Group'" :design="3" />
                </b-collapse>
              </div>
              <vb-no-record v-if="isEmpty(filterCallQueues)" :text="'No call queue available'" :design="3" />
            </div>
          </template>
          <template v-else>
            <vb-table 
              :isListEmpty="filterCallQueues.length==0" 
              :listLength="filterCallQueues.length" 
              class="latestTableDesign-withBlackBorders-again showGraphIconOnlyOnHover"
            >
              <template slot="header">
                <tr>
                  <th class="dialer-has-sort">
                    <span class="dialer-sorted-by ascending">Label</span>
                  </th>
                  <th class="dialer-has-sort dialer-col-right">
                    <span>Members</span>
                  </th>
                  <th class="dialer-has-sort dialer-col-right"></th>
                </tr>
              </template>
              <template #body="{ start, end }">
                <tr v-for="(callQueue, index ) in filterCallQueues"
                  :key="callQueue.voipaccount || callQueue.id"
                  v-show="index >= start && index <= end" 
                >
                  <td class="dialer-row-title one">
                    <Info class="w-fit-content" :is_suspended_show="!!callQueue.voipaccount" :id="callQueue.voipaccount || callQueue.id" />
                  </td>
                  <td class="dialer-row-title dialer-col-right four" >
                    <div v-if="!callQueue.queue_service" class="d-flex justify-content-end">
                      <a class="dialer-link-more text-decoration-underline" @click="membersModalData.type='queue';membersModalData.items = callQueue.members;$modal.show('ShowMembersOnlyModal')">
                        {{ callQueue | get_property('members.length',0) }} users
                      </a>
                    </div>
                  </td>
                  <td class="dialer-row-title dialer-col-right three">
                    <div class="d-flex justify-content-end settingsGridView-bottomIcons-container showOnly-OnHover-insideTable">
                      <div class="mr-3 cursor_pointer_no_hover" v-b-popover.hover.top="`Call`" @click="dialCallQueue(callQueue)">
                        <vb-icon icon="newScheduling-call-icon" height="35px" width="35px" /> 
                      </div>
                      <template v-if="getProperty(callQueue,`call_queue_status.${getCurrentUser.account}.adminforce`,'no')=='no'">
                        <div class="cursor_pointer_no_hover" v-b-popover.hover.top="`${callQueue.is_favourite ? 'Remove from favourite' : 'Add to favourite'}`" @click="toggleCallQueueFavourite(callQueue)">
                          <vb-icon :icon="callQueue.is_favourite ? 'newScheduling-favourite-icon' : 'newScheduling-notfavourite-icon'" height="35px" width="35px" /> 
                        </div>
                      </template>
                    </div>
                  </td>
                </tr>
              </template>
            </vb-table>
          </template>
        </template>

        <template v-else-if="selected.tab == 'addressbook'">
          <div class="d-flex align-items-center justify-content-between mt-16px">
            <div class="dialer-tabs">
              <div class="dialer-tab-headers align-items-center justify-content-between mb-0">
                <div class="d-flex align-items-center">
                  <div @click="selected.subTab='my'" :class="`dialer-tab text-capitalize ${selected.subTab=='my' ? 'selected' : ''}`"> My </div>
                  <div @click="selected.subTab='global'" :class="`dialer-tab text-capitalize ${selected.subTab=='global' ? 'selected' : ''}`"> Organization </div>
                  <!-- <div @click="selected.subTab='add';selected.addContactTab = '';selected.show_addContactTab = false" :class="`dialer-tab text-capitalize ${selected.subTab=='add' ? 'selected' : ''}`"> 
                    <b-icon icon="plus-lg" />
                  </div> -->
                </div>
              </div>
            </div>
            <!-- <template v-if="selected.subTab=='my'">
              <b-form class="whiteBGinputWithGreyRoundedBorder w-25" @submit.prevent="''">
                <b-input v-model="filter.local_addressbooks.search" placeholder="Search here" class="w-100 mx-1" />
              </b-form>
            </template>
            <template v-if="selected.subTab=='global'">
              <b-form class="whiteBGinputWithGreyRoundedBorder w-25" @submit.prevent="''">
                <b-input v-model="filter.global_addressbooks.search" placeholder="Search here" class="w-100 mx-1" />
              </b-form>
            </template> -->
            
            <template v-if="selected.subTab=='my'">
              <button class="newButton" @click="selected.addContactTab = 'local';selected.subTab = 'add';selected.show_addContactTab = true">
                <vb-icon icon="squared-add-icon" height="38px" width="38px" />
                <span class="newButton-textPart position-relative d-flex justify-content-center">
                  Add my contact
                </span>
              </button>
            </template>
            <template v-if="selected.subTab=='global'">
              <button class="newButton" @click="selected.addContactTab = 'global';selected.subTab = 'add';selected.show_addContactTab = true">
                <vb-icon icon="squared-add-icon" height="38px" width="38px" />
                <span class="newButton-textPart position-relative d-flex justify-content-center">
                  Add global contact
                </span>
              </button>
            </template>
          </div>
          <template v-if="selected.subTab=='my'">
            <template v-if="gridView">
              <div class="settingsGridView-container mt-20px">
                <div class="settingsGridView-item"  v-for="addressbook in filterLocalAddressBooks" :key="addressbook.real_id">
                  <vb-avatar :image="addressbook | get_property('profile_image')" />
                  <div class="settingsGridView-display_name">{{`${addressbook.firstName} ${addressbook.lastName || ''}`}}</div>
                  <div class="settingsGridView-email">{{ addressbook | get_property('main_phone.number') | number_formater }}</div>
                  <div class="d-flex settingsGridView-bottomIcons-container mt-20px">
                    <div class="mr-3 cursor_pointer_no_hover" v-b-popover.hover.top="`Call`" @click="audioCall(addressbook)">
                      <vb-icon icon="newScheduling-call-icon" height="55px" width="55px" /> 
                    </div>
                    <div :disabled="api.toggle_favourite.send.includes(addressbook.real_id)" @click="toggleFavouriteAddressbook(addressbook)" class="cursor_pointer_no_hover" v-b-popover.hover.top="`${addressbook.is_favourite ? 'Remove from favourite' : 'Add To favourite'}`">
                      <vb-icon :icon="addressbook.is_favourite ? 'newScheduling-favourite-icon' : 'newScheduling-notfavourite-icon'" height="55px" width="55px" /> 
                    </div>
                  </div>
                </div>
                <vb-no-record v-if="isEmpty(filterLocalAddressBooks)" :text="'No contacts found'" :design="3" />
              </div>
            </template>
            <template v-else>
              <vb-table 
                textNoRecord=" " 
                id="users-table" 
                class="latestTableDesign-withBlackBorders-again showGraphIconOnlyOnHover" 
                :isListEmpty="isEmpty(filterLocalAddressBooks)" 
                :listLength="filterLocalAddressBooks.length" 
                :perPage="filterLocalAddressBooks.length" 
                :conditions="{ 
                  loading: { 
                    show: false 
                  }, 
                  pagination: { 
                    show: false 
                  }, 
                }"
              >
                <template slot="header">
                  <tr>
                    <th class="dialer-has-sort">
                      <span>Name</span>
                    </th>
                    <th class="dialer-has-sort"></th>
                    <th class="dialer-has-sort dialer-col-right"></th>
                  </tr>
                </template>
                <template #body="{ start, end }">
                  <tr v-for="(addressbook,index) in filterLocalAddressBooks" :key="addressbook.real_id" v-show="index >= start && index <= end" >
                    <td class="dialer-row-title one">
                      <div class="d-flex align-items-center">
                        <vb-avatar :image="addressbook | get_property('profile_image')" />
                        <div class="ml-3">
                          <div class="settingsGridView-display_name">{{`${addressbook.firstName} ${addressbook.lastName || ''}`}}</div>
                          <div class="settingsGridView-email">{{ addressbook | get_property('main_phone.number') | number_formater }}</div>
                        </div>
                      </div>
                    </td>
                    <td class="dialer-row-title three" >
                      
                    </td>
                    <td class="dialer-row-title dialer-col-right four">
                      <div class="d-flex justify-content-end settingsGridView-bottomIcons-container showOnly-OnHover-insideTable">
                        <div class="mr-3 cursor_pointer_no_hover" v-b-popover.hover.top="`Call`" @click="audioCall(addressbook)">
                          <vb-icon icon="newScheduling-call-icon" height="35px" width="35px" /> 
                        </div>
                        <div :disabled="api.toggle_favourite.send.includes(addressbook.real_id)" @click="toggleFavouriteAddressbook(addressbook)" class="cursor_pointer_no_hover" v-b-popover.hover.top="`${addressbook.is_favourite ? 'Remove from favourite' : 'Add to favourite'}`">
                          <vb-icon :icon="addressbook.is_favourite ? 'newScheduling-favourite-icon' : 'newScheduling-notfavourite-icon'" height="35px" width="35px" /> 
                        </div>
                      </div>
                    </td>
                  </tr>
                </template>
              </vb-table>
            </template>
          </template>
          <template v-if="selected.subTab=='global'">
            <template v-if="gridView">
              <div class="settingsGridView-container mt-20px">
                <div class="settingsGridView-item"  v-for="addressbook in filterGlobalAddressBooks" :key="addressbook.real_id">
                  <vb-avatar :image="addressbook | get_property('profile_image')" />
                  <div class="settingsGridView-display_name">{{`${addressbook.firstName} ${addressbook.lastName || ''}`}}</div>
                  <div class="settingsGridView-email">{{ addressbook | get_property('main_phone.number') | number_formater }}</div>
                  <div class="d-flex settingsGridView-bottomIcons-container mt-20px">
                    <div class="mr-3 cursor_pointer_no_hover" v-b-popover.hover.top="`Call`" @click="audioCall(addressbook)">
                      <vb-icon icon="newScheduling-call-icon" height="55px" width="55px" /> 
                    </div>
                    <div :disabled="api.toggle_favourite.send.includes(addressbook.real_id)" @click="toggleFavouriteAddressbook(addressbook)" class="cursor_pointer_no_hover" v-b-popover.hover.top="`${addressbook.is_favourite ? 'Remove from favourite' : 'Add to favourite'}`">
                      <vb-icon :icon="addressbook.is_favourite ? 'newScheduling-favourite-icon' : 'newScheduling-notfavourite-icon'" height="55px" width="55px" /> 
                    </div>
                  </div>
                </div>
                <vb-no-record v-if="isEmpty(filterGlobalAddressBooks)" :text="'No contacts found'" :design="3" />
                <!-- <div v-if="isEmpty(filterGlobalAddressBooks)" class="emptyScreenContainer mt-32px b-0 p-0">
                  <img width="50%" height="auto" v-if="GET_LOCAL_SETTING_IS_DARK_MODE" :src="require('@/assets/images/emptyScreenImages/darkMode/addressBook.png')"/>
                  <img width="50%" height="auto" v-else :src="require('@/assets/images/emptyScreenImages/addressBook.png')"/>
                  <div class="emptyScreenContainer-heading mt-32px">Explore Address Book</div>
                </div> -->
              </div>
            </template>
            <template v-else>
              <vb-table 
                textNoRecord=" " 
                id="users-table" 
                class="latestTableDesign-withBlackBorders-again showGraphIconOnlyOnHover" 
                :isListEmpty="isEmpty(filterGlobalAddressBooks)" 
                :listLength="filterGlobalAddressBooks.length" 
                :perPage="filterGlobalAddressBooks.length" 
                :conditions="{ 
                  loading: { 
                    show: false 
                  }, 
                  pagination: { 
                    show: false 
                  }, 
                }"
              >
                <template slot="header">
                  <tr>
                    <th class="dialer-has-sort">
                      <span>Name</span>
                    </th>
                    <th class="dialer-has-sort"></th>
                    <th class="dialer-has-sort dialer-col-right"></th>
                  </tr>
                </template>
                <template #body="{ start, end }">
                  <tr v-for="(addressbook,index) in filterGlobalAddressBooks" :key="addressbook.real_id" v-show="index >= start && index <= end" >
                    <td class="dialer-row-title one">
                      <div class="d-flex align-items-center">
                        <vb-avatar :image="addressbook | get_property('profile_image')" />
                        <div class="ml-3">
                          <div class="settingsGridView-display_name">{{`${addressbook.firstName} ${addressbook.lastName || ''}`}}</div>
                          <div class="settingsGridView-email">{{ addressbook | get_property('main_phone.number') | number_formater }}</div>
                        </div>
                      </div>
                    </td>
                    <td class="dialer-row-title three" >
                      
                    </td>
                    <td class="dialer-row-title dialer-col-right four">
                      <div class="d-flex justify-content-end settingsGridView-bottomIcons-container showOnly-OnHover-insideTable">
                        <div class="mr-3 cursor_pointer_no_hover" v-b-popover.hover.top="`Call`" @click="audioCall(addressbook)">
                          <vb-icon icon="newScheduling-call-icon" height="35px" width="35px" /> 
                        </div>
                        <div :disabled="api.toggle_favourite.send.includes(addressbook.real_id)" @click="toggleFavouriteAddressbook(addressbook)" class="cursor_pointer_no_hover" v-b-popover.hover.top="`${addressbook.is_favourite ? 'Remove from favourite' : 'Add to favourite'}`">
                          <vb-icon :icon="addressbook.is_favourite ? 'newScheduling-favourite-icon' : 'newScheduling-notfavourite-icon'" height="35px" width="35px" /> 
                        </div>
                      </div>
                    </td>
                  </tr>
                </template>
              </vb-table>
            </template>
          </template>
          <template v-if="selected.subTab=='add'">
            <!-- <div v-if="!selected.show_addContactTab" class="addUserBox-container">
              <div class="addUserBox" @click="selected.addContactTab = 'local';selected.show_addContactTab = true">
                <vb-icon icon="AddUser-icon" class="AddUser-icon" />
                <h2 class="">Add my contact</h2>
                <p>
                  Add a new my contact
                </p>
              </div>

              <div class="addUserBox" @click="selected.addContactTab = 'global';selected.show_addContactTab = true">
                <vb-icon icon="AddUser-icon" class="AddUser-icon" />
                <h2 class="">Add organization contact</h2>
                <p>
                  Add a new organization contact
                </p>
              </div>
            </div> -->
            <template v-if="selected.show_addContactTab">
              <!-- selected.subTab = selected.addContactTab -->
              <Addressbooks :openedFromMeetings="true" :tabSelected="selected.addContactTab" @created="selected.addContactTab == 'local' ? selected.subTab = 'my' : selected.subTab = selected.addContactTab ;selected.show_addContactTab = false;" />
            </template>
          </template>
        </template>
      </div>
      <modal 
        class="dialer_animation right_side_popup m-w-500 addExtension-voicemailModal addRemove_userModal bigSquareCheckbox CallQueueAddRemoveUsersModal min-width-50percent-modal fixScrollIssue"
        height="auto"
        name="ShowMembersOnlyModal"
      >
        <div class="dialer-edit-header">
          <div>
            <h2 class="dialer-settings-title newer mb-0">Members</h2>
          </div>
          <div class="dialer-edit-actions">
            <button class="newDoneButton" @click="$modal.hide('ShowMembersOnlyModal');membersModalData.items = []">
              <vb-icon icon="squared-done-icon" height="38px" width="38px" />
              <span class="newDoneButton-textPart">Done</span>
            </button>
          </div>
        </div>
        <div class="latestGreyBox-9-9-2023 mt-20px">
          <div v-if="membersModalData.type=='queue'" class="whiteBoxWithBorderInsideGreyBox mt-20px">
            <div class="whiteBoxWithBorderInsideGreyBox-row min-height-unset" v-for="item in membersModalData.items" :key="item.membername">
              <div class="w-100 d-flex justify-content-between align-item-center">
                <Info :id="item.membername" :is_blf="false" />
                <div class="d-flex align-items-center">
                  <div class="mr-3 cursor_pointer_no_hover" v-b-popover.hover.top="`Call`" @click="dial(item)">
                    <vb-icon icon="newScheduling-call-icon" height="35px" width="35px" /> 
                  </div>
                  <div class="mr-3 cursor_pointer_no_hover" :id="`RightSideUsersChatOption-${item.voipaccount}`" 
                    v-b-tooltip :title="getUserPermissions.chat?'':'Your package does not support this feature'"
                    :class="['' , !getUserPermissions.chat ? 'for-disable' : '']"
                  >
                    <div v-b-popover.hover.top="`Chat`" :class="!getUserPermissions.chat ? 'for-disable-disable' : ''"  @click="!getUserPermissions.chat ? '' :chatUser(item)">
                      <vb-icon icon="newScheduling-chat-icon" height="35px" width="35px" />  
                    </div>
                  </div>
                  <div class="mr-3 cursor_pointer_no_hover" v-b-popover.hover.top="`Video call`" @click="videoCall(item)">
                    <vb-icon icon="newScheduling-video-icon" height="35px" width="35px" />  
                  </div>
                </div>
              </div>
            </div>
            <vb-no-record class="my-4" v-if="isEmpty(membersModalData.items)" :design="3" />
          </div>
          <div v-else class="whiteBoxWithBorderInsideGreyBox mt-20px">
            <div class="whiteBoxWithBorderInsideGreyBox-row min-height-unset" v-for="item in membersModalData.items" :key="item.membername">
              <div class="w-100 d-flex justify-content-between align-item-center">
                <Info :id="item.accountcode" :is_blf="false" />
                <div class="d-flex align-items-center">
                  <div class="mr-3 cursor_pointer_no_hover" v-b-popover.hover.top="`Call`" @click="dial(item)">
                    <vb-icon icon="newScheduling-call-icon" height="35px" width="35px" /> 
                  </div>
                  <div class="mr-3 cursor_pointer_no_hover" :id="`RightSideUsersChatOption-${item.voipaccount}`" 
                    v-b-tooltip :title="getUserPermissions.chat?'':'Your package does not support this feature'"
                    :class="['' , !getUserPermissions.chat ? 'for-disable' : '']"
                  >
                    <div v-b-popover.hover.top="`Chat`" :class="!getUserPermissions.chat ? 'for-disable-disable' : ''"  @click="!getUserPermissions.chat ? '' :chatUser(item)">
                      <vb-icon icon="newScheduling-chat-icon" height="35px" width="35px" />  
                    </div>
                  </div>
                  <div class="mr-3 cursor_pointer_no_hover" v-b-popover.hover.top="`Video call`" @click="videoCall(item)">
                    <vb-icon icon="newScheduling-video-icon" height="35px" width="35px" />  
                  </div>
                </div>
              </div>
            </div>
            <vb-no-record class="my-4" v-if="isEmpty(membersModalData.items)" :design="3" />
          </div>
        </div>
      </modal>
    </section>
  </div>
</template>
<script>
import { events, VOIP_API } from "../../utils";
// import Info from "../Lists/Info.vue";
import { mapGetters } from 'vuex';
import { MATTERMOST_API, mattermost } from '@/MatterMost';
import Info from '../Lists/Info.vue';
import Addressbooks from '../rightsidebar/Addressbooks.vue'
function get_username(data,email=''){
  return ((data?.user?.email2 || data?.user?.email) === email ? data.user.ac_user : (data?.user?.ac_user != data?.user?.email) ? data?.user?.ac_user : data?.user?.email2 || data?.user?.email || data?.user?.ac_user) ?? ''
}
export default {
  name: 'Schedule',
  components: {
    Info,
    Addressbooks
  },
  data() {
    return {
      tabs : [
        {text:'Users',value:'users',icon: 'squared-singleUser-icon'},
        {text:'Teams',value:'teams',icon: 'squared-multipleUsers-icon'},
        {text:'Call queues',value:'queue',icon: 'squared-callQueue-icon'},
        {text:'Favourites',value:'favourites',icon: 'newScheduling-favourite-icon'},
        // {text:'Addressbook',value:'addressbook'},
      ],
      selected: {
        tab: 'users',
        subTab: 'my',
        addContactTab: '',
        show_addContactTab: false
      },
      api: {
        toggle_user_fav: this.$helperFunction.apiInstance({ send: [] }),
        chat_user: this.$helperFunction.apiInstance({  }),
        toggle_team_fav: this.$helperFunction.apiInstance({ send: [] }),
        toggle_call_queue_fav: this.$helperFunction.apiInstance({ send: [] }),
        call_queue: this.$helperFunction.apiInstance(),
        toggle_favourite: this.$helperFunction.apiInstance({
          send: [],
        }),
        delete_addressbook: this.$helperFunction.apiInstance({
          send: '',
        }),
      },
      assets: {
        user_male: require("@/assets/images/Male.png"),
      },
      filter: {
        users: {
          search: '',
        },
        teams: {
          search: '',
        },
        call_queue: {
          search: '',
        },
        local_addressbooks: {
          search: '',
        },
        global_addressbooks: {
          search: '',
        },
      },
      gridView: true,
      conditions: {
        more: '',
        collapse: '',
      },
      membersModalData: {
        items : [],
        type: ''
      },

    };
  },
  inject:[
    'isEmpty',
    'getProperty',
    'appNotify'
  ],
  computed: {
    ...mapGetters([
      'getUserPermissions',
      'getMattermostCurrentUser',
      'getCurrentUser',
      'getVoipTeams',
      'getVoipUsers',
      'getCurrentUserVoipCallQueues',
      'getVoipCallQueues',
      'getVoipUsersAlises',
      'getVoipUserAccountcode',
    ]),
    searchedUsers() {
      const search = this.filter.users.search.toLowerCase().trim();
      if (!search) return Object.values(this.$store.state.common.voipusers);
      var array = Object.values(this.$store.state.common.voipusers);
      return array.filter((event) =>
        `${event.extn || ""} ${event?.user?.display_name || ""}`
          .toLowerCase()
          .trim()
          .includes(search)
      );
    },
    filterTeams() {
      const search = this.filter.teams.search.toLowerCase().trim();
      return this.$store.getters.getVoipTeams.filter((event) => `${event.name}`.toLowerCase().trim().split('-').join('').includes(search));
    },
    favouriteTeams(){ return this.getVoipTeams.filter(team=>team.is_favourite) },
    favouriteUsers(){ return Object.values(this.getVoipUsers)?.filter?.(user=>user.is_favourites && user.voipaccount!=this.getCurrentUser.account) },
    favouriteCallQueue(){ return this.getVoipCallQueues.filter(call_queue=>call_queue.is_favourite) },
    favouriteAddressBook(){ return [...this.$store.state.common.address_books.local.data,...this.$store.state.common.address_books.global.data].filter(item=>!!item.is_favourite) },
    filterCallQueues() {
      const search = this.filter.call_queue.search.toLowerCase().trim();
      return this.getCurrentUserVoipCallQueues.filter((event) => `${event.label}`.toLowerCase().trim().split('-').join('').includes(search));
    },
    filterLocalAddressBooks(){
      const search = this.filter.local_addressbooks.search.toLowerCase().trim();
      return this.$store.state.common.address_books.local.data.filter((data) =>
        `${data.firstName} ${data.lastName} ${data.all_numbers} ${data.all_formated_numbers}`
          .toLowerCase()
          .trim()
          .includes(search)
      );
    },
    filterGlobalAddressBooks(){
      const search = this.filter.global_addressbooks.search.toLowerCase().trim();
      return this.$store.state.common.address_books.global.data.filter((data) =>
        `${data.firstName} ${data.lastName} ${data.all_numbers} ${data.all_formated_numbers}`
          .toLowerCase()
          .trim()
          .includes(search)
      );
    },
  },
  filters: {
    usernameDisplay(value,email=''){
      return get_username(value,email)
    },
    getAllDevices(id){
      const key = id;
      if (!key) return {};
      const user = this.getVoipUsersAlises[key] || this.getVoipUsersAlises[this.getVoipUserAccountcode[key]];
      return { devices: user?.devices ?? null, };
    },
    accountType(val) {
      if(val=='WEBEXT') return 'devices-webExtension-icon'
      else if(val === "USER") return "monitorDashboard-table-user";
      else if(val=='DESKTOPEXT') return 'devices-desktopExtension-icon'
      else if(val=='MOBILEEXT') return 'devices-mobileExtension-icon'
      else if(val=='HEADEXT') return 'devices-headExtension-icon'
      else if(val=='INTEGRATIONEXT') return 'devices-headExtension-icon'
      else if(val=='EXTERNALEXT') return 'devices-headExtension-icon'
      else return 'devices-webExtension-icon'
    },
  },
  methods: {
    async chatUser(user) {
      if(!user?.user?.user_chat_id || this.api.chat_user.send) return;
      let channel_id = user.channel_id
      try {
        this.api.chat_user.send=true
        if(!channel_id){
          const { data: channel } = await MATTERMOST_API.endpoints.channels.createDirectChannel([
            user.user.user_chat_id,
            this.getMattermostCurrentUser?.id
          ]);
          channel_id=channel.id
          mattermost.emitOnUserLevel(mattermost.events.direct_added,{
            channel_id: channel_id
          })
          this.$set(this.$store.state.common.voipusers[user.voipaccount],'channel_id',channel_id)
        }
        this.$store.state.global_conditions.tab='chat'
        this.$root.$emit(events.pinned_post_changed,{
          channel_id: channel_id
        })
        await this.$nextTick()
        this.$root.$emit(events.open_chat,{
          channel_id: channel_id,
        })
        this.$emit('close-side-bar')
      } catch (ex) {
        this.appNotify({
          message: ex.own_message,
          type: 'danger',
        })
      } finally {
        this.api.chat_user.send=false
      }
    },
    // chatUser(user) {

    //   this.$modal.show("ChatUserModal", { user: user });
    // },
    videoCall(user) {
      this.$root.$emit(events.video_call, {
        accounts: [user.voipaccount],
        call_type: "video",
      });
    },
    dial(user) {
      this.$root.$emit(events.audio_call, {
        number: user.extn,
      });
    },
    dialinringing(user){
      this.$root.$emit(events.audio_call, { 
        number: user.extn,
        call_type: 'blf_call',
        id: user.voipaccount,
      });
    },
    toggleUserFavourite(user){
      let vm = this
      const voipaccount = user.voipaccount
      const is_favourites = user.is_favourites
      if(vm.api.toggle_user_fav.send.includes(voipaccount)) return;
      vm.api.toggle_user_fav.send.push(voipaccount)
      VOIP_API.endpoints.user.toggleFavourite({
        user_accountcode: voipaccount, 
      })
      .then(()=>{
        vm.$store.state.common.voipusers[voipaccount].is_favourites = !vm.$store.state.common.voipusers[voipaccount].is_favourites
        vm.appNotify({
          message: `Successfully ${is_favourites ? 'Remove from Favourite' : 'Add to Favourite'}`,
          type: 'success',
        })
      })
      .catch((ex)=>{
        vm.appNotify({
          message: ex.own_message,
          type: 'danger',
        })
      })
      .finally(()=>{
        if(vm.api.toggle_user_fav.send.includes(voipaccount)) vm.api.toggle_user_fav.send.splice(vm.api.toggle_user_fav.send.indexOf(voipaccount),1)
      })
    },

    
    async chatTeam(team){
      if(!team?.channel_id || this.api.chat_user.send) return;
      let channel_id = team.channel_id
      try {
        this.api.chat_user.send=true
        this.$store.state.global_conditions.tab='chat'
        this.$root.$emit(events.pinned_post_changed,{
          channel_id: channel_id
        })
        await this.$nextTick()
        this.$root.$emit(events.open_chat,{
          channel_id: channel_id,
        })
        this.$emit('close-side-bar')
      } catch (ex) {
        this.appNotify({
          message: ex.own_message,
          type: 'danger',
        })
      } finally {
        this.api.chat_user.send=false
      }
    },
    dialTeam(team){
      this.$root.$emit(events.audio_call, {
        number: team.ringroup.extn,
        id: team.real_id,
      });
    },
    toggleFavourite(team){
      let vm = this
      const real_id = team.real_id
      const is_favourite = team.is_favourite
      if(vm.api.toggle_team_fav.send.includes(real_id)) return;
      vm.api.toggle_team_fav.send.push(real_id)
      VOIP_API.endpoints.teams.toogleFavourite({ 
        accountcode: vm.getCurrentUser.account, 
        teamId: real_id, 
      })
      .then(()=>{
        return vm.$store.state.common.teams.data.findIndex(item=>item.real_id==real_id)
      })
      .then((index)=>{
        vm.$store.state.common.teams.data[index].is_favourite = !vm.$store.state.common.teams.data[index].is_favourite
        vm.appNotify({
          message: `Successfully ${is_favourite ? 'Remove from Favourite' : 'Add to Favourite'}`,
          type: 'success',
        })
      })
      .catch((ex)=>{
        vm.appNotify({
          message: ex.own_message,
          type: 'danger',
        })
      })
      .finally(()=>{
        if(vm.api.toggle_team_fav.send.includes(real_id)) vm.api.toggle_team_fav.send.splice(vm.api.toggle_team_fav.send.indexOf(real_id),1)
      })
    },

    
    toggleCallQueueFavourite(call_queue){
      let vm = this
      const voipaccount = call_queue.voipaccount
      const is_favourite = call_queue.is_favourite
      if(vm.api.toggle_call_queue_fav.send.includes(voipaccount)) return;
      vm.api.toggle_call_queue_fav.send.push(voipaccount)
      VOIP_API.endpoints.call_queues.toggleFavourite({
        accountcode: voipaccount, 
      })
      .then(()=>{
        return vm.$store.state.common.call_queues.data.findIndex(item=>item.voipaccount==voipaccount)
      })
      .then((index)=>{
        vm.$store.state.common.call_queues.data[index].is_favourite = !vm.$store.state.common.call_queues.data[index].is_favourite
        vm.appNotify({
          message: `Successfully ${is_favourite ? 'Remove from Favourite' : 'Add to Favourite'}`,
          type: 'success',
        })
      })
      .catch((ex)=>{
        vm.appNotify({
          message: ex.own_message,
          type: 'danger',
        })
      })
      .finally(()=>{
        if(vm.api.toggle_call_queue_fav.send.includes(voipaccount)) vm.api.toggle_call_queue_fav.send.splice(vm.api.toggle_call_queue_fav.send.indexOf(voipaccount),1)
      })
    },
    dialCallQueue(call_queu){
      this.$root.$emit(events.audio_call, {
        number: call_queu.extn,
        id: call_queu.voipaccount,
      });
    },

    
    async toggleFavouriteAddressbook(addressbook){
      const id = addressbook?.real_id ?? ''
      const is_favourite = addressbook?.is_favourite
      const is_global = addressbook?.global==1
      if(!id || this.api.toggle_favourite.send.includes(id)) return;
      try {
        this.api.toggle_favourite.send.push(id)
        await VOIP_API.endpoints.address_books.toggleFavourite({
          id: id,
          action: is_favourite ? 'remove' : 'add'
        })
        if(is_global){
          const index = await this.$store.state.common.address_books.global.data.findIndex((item)=>item.real_id==id);
          this.$store.state.common.address_books.global.data[index].is_favourite=~~!is_favourite;
        } else {
          const index = await this.$store.state.common.address_books.local.data.findIndex((item)=>item.real_id==id);
          this.$store.state.common.address_books.local.data[index].is_favourite=~~!is_favourite;
        }
        this.appNotify({
          message: `Successfully ${is_favourite ? 'Un Favourite' : 'Favourite'}!`,
          type: "success",
        })
      } catch (ex) {
        this.appNotify({
          message: ex.own_message || ex.message,
          type: 'danger',
        })
      } finally {
        this.api.toggle_favourite.send.splice(this.api.toggle_favourite.send.indexOf(id),1)
      }
    },
    audioCall(addressbook) {
      if (this.api.delete_addressbook.send==addressbook.real_id) return;
      if (addressbook?.phones?.length < 2) {
        const number_data = addressbook.main_phone;
        if (!number_data) return;
        this.$root.$emit(events.audio_call, {
          number: number_data.number,
          id: number_data.uid,
          type: 'addressbook',
        });
      } else {
        this.$modal.show("SelectAddressBookNumberModal", {
          addressbook: addressbook,
        });
      }
    },
  },
}
</script>

<style>

</style>