import Vue from 'vue';
import Vuex from 'vuex';
import { phone } from "../sip/app";

// import { alert } from './alert.module';
// import authentication  from './authentication.module';
import { mattermost } from './modules/mattermost.module'; // mattermost module
import { common } from './modules/common.module';
import { centrifuge } from './modules/centrifuge.module';
import logger from './modules/logger.module';
import app_tour from './modules/app.tour.module';
import pamarlyModule from './modules/pamarly';
import errorsModule from './modules/errors.module';
import crmModule from './modules/crm';
import localSettingModule from './modules/local.settings.module';
import calls from './modules/calls.module';
import pamarlyPlugin from './plugins/pamarly';
import crmPlugin from './plugins/crm';
import localSettingPlugin from './plugins/local.settings';
import Timer from "timer.js";

Vue.use(Vuex);
export default new Vuex.Store({
  plugins: [
    pamarlyPlugin,
    crmPlugin,
    localSettingPlugin,
  ],
  modules: {
    calls,
    centrifuge,
    // alert,
    // authentication,
    common,
    pamarly: pamarlyModule,
    crm: crmModule,
    errors: errorsModule,
    local_setting: localSettingModule,
    app_tour,
    logger,
    mattermost, // implement in store modules
  },
  state: {
    power_dialer: {
      start_sheet_id: '',
    },
    logout_running: false,
    theme: {
      mode: 'colored3',
      system_mode: '',
    },
    application: 'dialer',
    notifys: [],
    global_conditions: {
      add_addressbooks: false,
      blur_bg: false,
      tab: false,
      is_right_bar_open: false,
      is_iframe: false,
      is_mobile_setting: false,
      is_picture_in_picture_mode: false,
    },
    integration_platform: '',
    sip: {
      state: {
        sip_register_notification: 0,
        is_connected: false,
        is_init: false,
        transfer: '',
        last_call_number: '',
        is_register: false,
        dnd: false,
        show_snooz: false,
        custom_snooz: '',
        snooz_time: 0,
        timer: null,
      },
      snooze: '',
      snooze_time: 0,
      phone: {},
      number: '',
      last_call_number: '',
      caller_id: '',
    },
    number: '',
    call_activity_timer: {},
    device: {
      mobile: false,
    },
    css_break_points: {
      b1: false,
      b2: false,
      b3_rightSideBar: false,
    },
    cp_device_info: {},
    us_number_progress: '',
    device_info: {},
    fp_device: {},
    media_devices_setting: {},
    electron: {
      app_view: 'web',
      app_version: '',
      name: '',
      dev_mode: false,
      idle_state: {
        state: 'active',
        seconds: 0,
      },
      update: {
        disabled: false,
        auto: false,
        error: '',
        version: '',
        version_available: '',
        state: 0,
        downloading: {
          percentage: 0,
          per_second: 0,
          download: 0,
          total: 0,
        },
      },
    }
  },
  getters: {
    isIntegration(state){ return process.env.VUE_APP_INTEGRATION=='true' || !!state.integration_platform },
    getIsMobile(state){ return state.device.mobile },
    getIsHRMApplication(state){ return state.application=='hrm' },
    getIsPamarlyApplication(state){ return state.application=='pamarly' },
    getIsCRMApplication(state){ return state.application=='crm' },
    getIsDialerApplication(state){ return state.application=='dialer' },
    getUserSettings(state,getters,rootState,rootGetters){ 
      let list = [
        {
          class: "dialer-numberPad-icon",
          // icon: "dialer-numberPad-icon",
          icon: "latest-settingsIcon-telephoneNumbers",
          m_icon: 'MyNumbers-IOSIcon',
          // label: "my numbers",
          // label: "Telephone numbers",
          label: "My numbers",
          component: "Numbers",
          width: "20",
          height: "19.999",
          viewBox: "0 0 18 18",
          isKeepAlive: true,
        },
        // {
        //   class: "dialer-smartPhone-icon",
        //   // icon: "dialer-smartPhone-icon",
        //   icon: "latest-settingsIcon-calls",
        //   m_icon: 'Calls-IOSIcon',
        //   label: "My calls",
        //   component: "CallCopyC",
        //   width: "20.481",
        //   height: "20",
        //   viewBox: "0 0 10 18.004",
        //   isKeepAlive: true,
        // },
        // {
        //   class: "dialer-voiceMail-icon",
        //   icon: "dialer-voiceMail-icon",
        //   m_icon: 'Voicemail-IOSIcon',
        //   label: "Voicemail",
        //   component: "VoicemailSetting",
        //   width: "24.5",
        //   height: "11.5",
        //   viewBox: "0 0 24.5 11.5",
        //   isKeepAlive: true,
        // },
        // {
        //   class: "dialer-devices-icon",
        //   // icon: "dialer-devices-icon",
        //   icon: "latest-settingsIcon-phones23",
        //   m_icon: 'Phones-IOSIcon',
        //   label: "My devices",
        //   component: "DevicesLatest",
        //   width: "20",
        //   height: "20",
        //   viewBox: "0 0 20.857 20",
        //   isKeepAlive: true,
        // },
        // {
        //   class: "dialer-soundWave-icon",
        //   icon: "dialer-soundWave-icon",
        //   label: "audio",
        //   component: "Sounds",
        //   width: "24",
        //   height: "18.001",
        //   viewBox: "0 0 24 18.001",
        //   isKeepAlive: false, 
        // },
        // {
        //   class: "dialer-microphone-icon",
        //   icon: "dialer-microphone-icon",
        //   m_icon: 'SoundLibrary-IOSIcon',
        //   label: "sound library",
        //   component: "Recordings",
        //   width: "12.5",
        //   height: "20",
        //   viewBox: "0 0 12.5 20",
        //   isKeepAlive: true,
        // },
        // {
        //   class: "dialer-conferenceCall-icon",
        //   icon: "dialer-conferenceCall-icon",
        //   label: "conference calling",
        //   component: "Conferences",
        //   width: "25.3",
        //   height: "22.3",
        //   viewBox: "0 0 25.3 22.3",
        //   isKeepAlive: true,
        // },
        // {
        //   class: "dialer-creditCard-icon",
        //   icon: "dialer-creditCard-icon",
        //   label: "earn free credit",
        //   component: "EarnFreeCredit",
        //   width: "21",
        //   height: "16",
        //   viewBox: "0 0 21 16",
        //   isKeepAlive: true,
        // },
        // {
        //   class: "dialer-voiceMail-icon",
        //   icon: "dialer-voiceMail-icon",
        //   label: "Trusted Device",
        //   component: "TrustedDevices",
        //   width: "24.5",
        //   height: "11.5",
        //   viewBox: "0 0 24.5 11.5",
        //   isKeepAlive: true,
        // },
        {
          class: "dialer-user-icon",
          // icon: "dialer-user-icon",
          // icon: "latest-settingsIcon-profile",
          icon: "latest-settingsIcon-mySettings",
          m_icon: 'Profile-IOSIcon',
          label: "My Settings",
          component: "Profile",
          width: "16.177",
          height: "20.001",
          viewBox: "0 0 16.305 16.295",
          isKeepAlive: true,
        },
        // {
        //   class: "dialer-integrationsSettings-icon",
        //   // icon: "dialer-integrationsSettings-icon",
        //   icon: "latest-settingsIcon-integrations",
        //   m_icon: 'Integrations-IOSIcon',
        //   label: "My integrations",
        //   component: "Integrations",
        //   width: "20.016",
        //   height: "19.998",
        //   viewBox: "0 0 16.01 16.012",
        //   isKeepAlive: true,
        // },
      ]
      if(rootGetters.getUserPermissions.customer_logs){
        list.push({
          class: "dialer-numberPad-icon",
          icon: "dialer-numberPad-icon",
          label: "customer logs",
          component: "CustomerLogs",
          width: "24",
          height: "18",
          viewBox: "0 0 18 18",
          isKeepAlive: true,
        })
      }
      // if(process.env.IS_ELECTRON){
      //   list.push({ 
      //     class: "dialer-updatesSettings-icon",
      //     // icon: "dialer-updatesSettings-icon",
      //     icon: "latest-settingsIcon-telephoneNumbers",
      //     label: "Updates",
      //     component: "ElectronSetting",
      //     width: "22.654",
      //     height: "22.674",
      //     viewBox: "0 0 22.654 22.674",
      //     isKeepAlive: false,
      //   })
      // }
      return list
    },
    getAdminSettings(state,getters,rootState,rootGetters){ 
      const list = [
        {
          class: "dialer-user-icon",
          // icon: "dialer-user-icon",
          icon: "latest-settingsIcon-users",
          m_icon: 'Profile-IOSIcon',
          label: "Users",
          component: "UsersSettings",
          width: "19.996",
          height: "17.484",
          viewBox: "0 0 16.305 16.295",
          isKeepAlive: true,
        },
        {
          class: "dialer-team-icon",
          // icon: "dialer-team-icon",
          icon: "latest-settingsIcon-teams",
          m_icon: 'Teams-IOSIcon',
          label: "Teams",
          component: "TeamsSettings",
          width: "20.37",
          height: "13.143",
          viewBox: "0 0 24.505 15.497",
          isKeepAlive: true,
        },
        {
          class: "dialer-numberPad-icon",
          // icon: "dialer-numberPad-icon",
          icon: "latest-settingsIcon-numbers",
          m_icon: 'MyNumbers-IOSIcon',
          label: "Numbers",
          component: "NumbersSettings",
          width: "15.477",
          height: "17.687",
          viewBox: "0 0 18 18",
          isKeepAlive: true,
        },
        // {
        //   class: "settings-conference-icon",
        //   icon: "settings-conference-icon",
        //   label: "Conference",
        //   component: "Conference",
        //   width: "26.608",
        //   height: "26.003",
        //   viewBox: "0 0 26.608 26.003",
        //   isKeepAlive: true,
        // },
        {
          class: "dialer-ivr-icon",
          // icon: "dialer-ivr-icon",
          icon: "latest-settingsIcon-ivr",
          m_icon: 'IVR-IOSIcon',
          label: "IVR",
          component: "Menus",
          width: "19.396",
          height: "20",
          viewBox: "0 0 23.1 24.1",
          isKeepAlive: true,
        },
        {
          class: "settings-callQueue-icon",
          // icon: "settings-callQueue-icon",
          icon: "latest-settingsIcon-callQueue",
          m_icon: 'CallQueue-IOSIcon',
          label: "Call queue",
          component: "CallQueues",
          width: "21.027",
          height: "20.005",
          viewBox: "0 0 25.266 20.867",
          isKeepAlive: true,
        },
        {
          class: "dialer-outlineCirclePause-icon",
          // icon: "dialer-outlineCirclePause-icon",
          icon: "latest-settingsIcon-musicOnHold",
          m_icon: 'MusicOnHold-IOSIcon',
          label: "Music on hold",
          component: "MusicOnHoldSettings",
          width: "16.667",
          height: "20.001",
          viewBox: "0 0 22 22",
          isKeepAlive: true,
        },
        // {
        //   class: "dialer-microphone-icon",
        //   // icon: "dialer-microphone-icon",
        //   icon: "latest-settingsIcon-soundLibrary",
        //   m_icon: 'SoundLibrary-IOSIcon',
        //   label: "sound library",
        //   component: "Recordings",
        //   width: "12.36",
        //   height: "17.975",
        //   viewBox: "0 0 12.5 20",
        //   isKeepAlive: true,
        // },
        // {
        //   class: "dialer-privacy-icon",
        //   icon: "dialer-privacy-icon",
        //   m_icon: 'BlockingAndPrivacy-IOSIcon',
        //   label: "Blocking and Privacy",
        //   // label: "privacy",
        //   component: "PrivacySettings",
        //   width: "18.2",
        //   height: "20.204",
        //   viewBox: "0 0 18.2 20.204",
        //   isKeepAlive: true,
        // },
        // {
        //   class: "settings-callRecording-icon",
        //   // icon: "settings-callRecording-icon",
        //   icon: "latest-settingsIcon-callRecordings",
        //   m_icon: 'CallRecording-IOSIcon',
        //   label: "Call recordings",
        //   component: "CallRecording",
        //   width: "15.664",
        //   height: "15.407",
        //   viewBox: "0 0 25.737 20.474",
        //   isKeepAlive: true,
        // },
        {
          class: "dialer-wallet-icon",
          // icon: "dialer-wallet-icon",
          icon: "latest-settingsIcon-billing",
          m_icon: 'Billing-IOSIcon',
          // label: "account",
          label: "Billing",
          component: "AccountSettings",
          width: "20",
          height: "13.59",
          viewBox: "0 0 21 19",
          isKeepAlive: true,
        },
        {
          class: "dialer-globalSetting-icon",
          // icon: "dialer-globalSetting-icon",
          icon: "latest-settingsIcon-globalSettings",
          m_icon: 'GlobalSetting-IOSIcon',
          label: "Global settings",
          component: "GlobalSettings",
          width: "21.072",
          height: "20.912",
          viewBox: "0 0 26 26",
          isKeepAlive: true,
        },
        // {
        //   class: "dialer-locaion-icon",
        //   // icon: "dialer-locaion-icon",
        //   icon: "latest-settingsIcon-locations",
        //   m_icon: 'Locations-IOSIcon',
        //   label: "Locations",
        //   component: "Location",
        //   width: "14.282",
        //   height: "20.4",
        //   viewBox: "0 0 15.5 20.5",
        //   isKeepAlive: true,
        // },
        // {
        //   class: "dialer-topUp-icon",
        //   icon: "dialer-topUp-icon",
        //   label: "Advance",
        //   component: "PickUp",
        //   width: "16.602",
        //   height: "25.6",
        //   viewBox: "0 0 16.602 25.6",
        //   isKeepAlive: true,
        // },
        // {
        //   class: "settings-supportTicket-icon",
        //   icon: "settings-supportTicket-icon",
        //   label: "Support Tickets",
        //   component: "SupportTicket",
        //   width: "27.027",
        //   height: "16.769",
        //   viewBox: "0 0 27.027 16.769",
        //   isKeepAlive: true,
        // },
      ] 
      if(rootGetters.getCurrentUser.logs_mode || rootGetters.getCurrentUser.logs_super_mode){
        list.push({
          class: "settings-supportTicket-icon",
          icon: "settings-supportTicket-icon",
          label: "Developer notes",
          component: "DeveloperNotes",
          width: "27.027",
          height: "16.769",
          viewBox: "0 0 27.027 16.769",
          isKeepAlive: false,
        })
      }
      return list
    },
    getAnalyticSettings(){ 
      return [
        {
          icon: "graph-up",
          m_icon: 'Summary-analytics-IOSIcon',
          label: "summary",
          component: "Summary",
        },
        {
          icon: "person",
          m_icon: 'Users-analytics-IOSIcon',
          label: "users",
          component: "Users",
        },
        {
          icon: "phone", 
          m_icon: 'MyNumbers-IOSIcon',
          label: "numbers",
          component: "AnalyticsNumbers",
        },
        {
          icon: "people",
          m_icon: 'Teams-analytics-IOSIcon',
          label: "teams",
          component: "Teams",
        },
        {
          icon: "people",
          m_icon: 'Teams-analytics-IOSIcon',
          label: "Voicemail",
          component: "Voicemail",
        },
        {
          icon: "file-earmark-text",
          m_icon: 'Voicemail-analytics-IOSIcon',
          label: "Reporting",
          // label: "Call Analytics",
          // component: "Reports",
          component: "Something2",
        },
      ] 
    },
    getIsDarkMode(state){ return !state.global_conditions.is_mobile_setting && (state.theme.mode!='system' ? state.theme.mode=='dark' : state.theme.system_mode=='dark') },
    getIscolored(state){ return !state.global_conditions.is_mobile_setting && state.theme.mode=='colored' },
    getIscolored2(state){ return !state.global_conditions.is_mobile_setting && state.theme.mode=='colored2' },
    getIscolored3(state){ return !state.global_conditions.is_mobile_setting && state.theme.mode=='colored3' },
    fpData(state){
      return {
        city: state.cp_device_info.city ?? state.fp_device?.ipLocation?.city?.name ?? '',
        country: state.cp_device_info.country ?? state.fp_device?.ipLocation?.country?.name ?? '',
        ip: state.cp_device_info.ip ?? state.fp_device?.ip ?? '',
        platform: process.env.IS_ELECTRON ? 'desktop' : process.env.VUE_APP_INTEGRATION=='true' ? 'integration' : 'web',
        platform_version: state.electron.app_version || '',
        os: state.cp_device_info.os ?? state.fp_device?.os ?? '',
        os_version: state.cp_device_info.os_version ?? state.fp_device?.osVersion ?? '',
        browser: state.cp_device_info.browser ?? state.fp_device?.browserName ?? '',
        browser_versiion: state.cp_device_info.browser_versiion ?? state.fp_device?.browserVersion ?? '',
        name: process.env.IS_ELECTRON ? state.electron.name || '' : process.env.VUE_APP_INTEGRATION=='true' ? state.integration_platform || '' : '',
        device_id: state.device_info.id ?? state.cp_device_info.device_id ?? state.fp_device?.visitorId ?? ''
      }
    },
  },
  actions: {
    snooz(context,payload){
      const { value, time, show, through_status_change, custom_snooz='' } = payload
      const snooz_time=time
      const dnd = phone.toggleDnd(value);
      const statuses = context.rootState.common.statuses
      const show_snooz=show
      const custom_snooz1=custom_snooz
      const startTimer = function () {
        if (context.state.sip.state.timer) {
          context.state.sip.state.timer.stop();
        }
        let timerr = new Timer({
          tick: 1,
          onstop() {
            // console.log("timer::stoped");
            context.commit('setSipSnoozTimer',null)
            context.commit('setSnoozTime',0)
          },
          onend() {
            // console.log("timer::end");
            if (context.state.sip.state.timer) {
              context.dispatch('snooz',false,0,true,false)
            }
            context.commit('setSipSnoozTimer',null)
            context.commit('setSnoozTime',0)
          },
        });
        timerr.start(snooz_time);
        context.commit('setSipSnoozTimer',timerr)
      }
      if(!through_status_change){
        if(dnd){
          const status =statuses.find(status=>status.status=='Do not disturb' && status.accountcode==null)
          if(status){
            context.dispatch('setStatus',status.id)
          }
          if (context.state.sip.state.timer) {
            context.state.sip.state.timer.stop();
          }
          if(snooz_time>0){
            startTimer()
          }
        } else {
          const status =statuses.find(status=>status.status=='Available' && status.accountcode==null)
          if(status){
            context.dispatch('setStatus',status.id)
          }
          if (context.state.sip.state.timer) {
            context.state.sip.state.timer.stop();
          }
        }
      }
      context.commit('setSipSnoozShowSnooz',show_snooz)
      context.commit('setSipSnoozCustomeSnooz',custom_snooz1)
      context.commit('setSipSnoozDND',dnd)
      context.commit('setSnoozTime',snooz_time)
    },
  },
  mutations: {
    pushCallActivityTimer(state,uid){
      var obj = {}
      Object.defineProperty(obj,uid,{
        value: {
          init_time: new Date(),
          end_time: '',
        },
        configurable: true,
        enumerable: true,
        writable: true,
      })
      state.call_activity_timer = {
        ...state.call_activity_timer,
        ...obj,
      }
    },
    setIsMobile(state,value){
      state.device.mobile=!!value
    },
    setSipSnoozTimer(state,value){
      state.sip.state.timer=value
    },
    setSipSnoozShowSnooz(state,value){
      state.sip.state.show_snooz=value
    },
    setSipSnoozCustomeSnooz(state,value){
      state.sip.state.custom_snooz=value
    },
    setSipSnoozDND(state,value){
      state.sip.state.dnd=value
    },
    setSnoozTime(state,value){
      state.sip.state.snooz_time=value
    },
    setMediaDevicesSetting(state,value){
      state.media_devices_setting=value
    },
    setApplication(state,data){ 
      if(data=='dialer'){
        state.global_conditions.tab='homeCopy'
      } else if(data=='hrm'){
        // state.global_conditions.tab='hrm-home'
        state.global_conditions.tab='HrmHomePage'
      } else if(data=='pamarly'){
        // state.global_conditions.tab='hrm-home'
        state.global_conditions.tab='pamarly-home'
      } else if(data=='crm'){
        // state.global_conditions.tab='hrm-home'
        state.global_conditions.tab='crm-deals'
      }
      state.application=data 
    },
    setDeviceInfo(state,value){
      state.cp_device_info=value ?? {}
    },
  },
});