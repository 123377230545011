<template>
  <div class="content-area d-flex h-100 dialer_main_body">
    <div class="dialer_main_body_inner w-100">
      <div v-if="!GET_LOCAL_SETTING_MOBILE_SETTINGS.tab" :class="['dialer_inner_left_side ',getIsMobile ? '' : 'd-r-768px-none']">
        <div class="d-flex ">
          <b-icon icon="x" @click="$store.state.global_conditions.tab='user_profile'" class="cursor_pointer XiconForClosingSettingsInMobile" font-scale="2" />
          <div class="first-section align-items-center">
            <h2>Settings</h2>
            <UserProfile class="w-auto"/>
          </div>
        </div>
        <div class="dialer_inner_left_side_inner allow-scroll mobileSettingsPanel">
          <ul class="list-unstyled">
            <li 
              v-for="tab in tabs.user" 
              :key="tab.component" 
              @click="SET_LOCAL_SETTING_MOBILE_SETTINGS_TAB(tab.component)" 
              :class="`dialer_tab_btn ${GET_LOCAL_SETTING_MOBILE_SETTINGS.tab==tab.component ? 'active' : ''}`"
            >
              <a :class="`text-capitalize ${getIsMobile ? 'formobileResponsive' : ''}`">
                <span class="dialer-settingPanel-icon">
                  <vb-icon :icon="tab.m_icon" />
                </span>
                <span class="dialer-settingPanel-text">{{ tab.label }}</span>
                <span>
                  <b-icon icon="chevron-right" />
                </span>
              </a>
            </li>
          </ul>
          <template v-if="getCurrentUser.administrator_account">
            <h2 class="dialer_tabs_title text-uppercase">administrator</h2>
            <ul class="list-unstyled">
              <li 
                v-for="tab in tabs.admin" 
                :key="tab.component" 
                @click="SET_LOCAL_SETTING_MOBILE_SETTINGS_TAB(tab.component)" 
                :class="`dialer_tab_btn ${GET_LOCAL_SETTING_MOBILE_SETTINGS.tab==tab.component ? 'active' : ''}`"
              >
                <a :class="`text-capitalize ${getIsMobile ? 'formobileResponsive' : ''}`">
                  <span class="dialer-settingPanel-icon">
                    <vb-icon :icon="tab.m_icon" />
                  </span>
                  <span class="dialer-settingPanel-text">{{ tab.label }}</span>
                  <span>
                    <b-icon icon="chevron-right" />
                  </span>
                </a>
              </li>
            </ul>
          </template>
          <template v-if="getCurrentUser.administrator_account">
            <h2 class="dialer_main_title text-capitalize">Analytics</h2>
            <ul class="list-unstyled">
              <li 
                v-for="tab in tabs.analytics" 
                :key="tab.component" 
                @click="SET_LOCAL_SETTING_MOBILE_SETTINGS_TAB(tab.component)" 
                :class="`dialer_tab_btn ${GET_LOCAL_SETTING_MOBILE_SETTINGS.tab==tab.component ? 'active' : ''}`"
              >
                <a class="text-capitalize formobileResponsive">
                  <span class="dialer-settingPanel-icon">
                    <vb-icon :icon="tab.m_icon" />
                  </span>
                  <span class="dialer-settingPanel-text">{{ tab.label }}</span>
                  <span>
                    <b-icon icon="chevron-right" />
                  </span>
                </a>
              </li>
            </ul>
          </template>
        </div>
      </div>
      <div class="dialer_inner_right_side">
        <keep-alive :include="keepAliveTabs">
          <component 
            :is="GET_LOCAL_SETTING_MOBILE_SETTINGS.tab" 
            @my-numbers="SET_LOCAL_SETTING_MOBILE_SETTINGS_TAB(getCurrentUser.administrator_account?'NumbersSettings':GET_LOCAL_SETTING_MOBILE_SETTINGS.tab)" 
            @back="GET_LOCAL_SETTING_MOBILE_SETTINGS.tab=''" 
          />
        </keep-alive>
      </div>
    </div>
  </div>
</template>

<script>
import ElectronSetting from "../components/Setting/ElectronSetting.vue";
import Location from "../components/Setting/Location.vue";
import Numbers from "../components/Setting/Numbers.vue";
import CallCopyC from "../components/Setting/CallCopyC.vue";
import DevicesLatest from "../components/Setting/DevicesLatest.vue";
import Profile from "../components/Setting/Profile.vue";
import Recordings from "../components/Setting/Recordings.vue";
import Menus from "../components/Setting/Menus.vue";
import CallQueues from "../components/Setting/CallQueues.vue";
import UsersSettings from "../components/Setting/UsersSettings.vue";
import TeamsSettings from "../components/Setting/TeamsSettings.vue";
import NumbersSettings from "../components/Setting/NumbersSettings.vue";
import PrivacySettings from "../components/Setting/PrivacySettings.vue";
import GlobalSettings from "../components/Setting/GlobalSettings.vue";
import Integrations from "../components/Setting/Integrations.vue";
import AccountSettings from "../components/Setting/AccountSettings.vue";
import CallRecording from "../components/Setting/CallRecording.vue";
import MusicOnHoldSettings from "../components/Setting/MusicOnHoldSettings.vue";
import VoicemailSetting from "../components/Voicemail/Setting.vue";
import CustomerLogs from "../components/Setting/CustomerLogs.vue";
import UserProfile from '../components/UserProfile.vue'
import DeveloperNotes from '../components/Setting/DeveloperNotes.vue'
import Summary from "../components/Analytics/Summary.vue";
import Users from "../components/Analytics/Users.vue";
import Teams from "../components/Analytics/Teams.vue";
import AnalyticsNumbers from "../components/Analytics/Numbers.vue";
import Reports from "../components/Analytics/Reports.vue";
import Something2 from "../components/Analytics/Something2.vue";
import Voicemail from "../components/Analytics/Voicemail.vue";
import AllExtensions from "../components/Analytics/AllExtensions.vue";
import { mapGetters, mapMutations } from 'vuex';
import { GET_LOCAL_SETTING_MOBILE_SETTINGS } from '@/store/helper/getters';
import { SET_LOCAL_SETTING_MOBILE_SETTINGS_TAB } from '@/store/helper/mutations';
export default {
  name: 'MobileSettings',
  components: {
    Numbers,
    CallCopyC,
    DevicesLatest,
    Recordings,
    VoicemailSetting,
    Profile,
    Integrations,
    CustomerLogs,
    ElectronSetting,
    UsersSettings,
    TeamsSettings,
    NumbersSettings,
    Menus,
    CallQueues,
    MusicOnHoldSettings,
    PrivacySettings,
    CallRecording,
    AccountSettings,
    GlobalSettings,
    Location,
    DeveloperNotes,
    UserProfile,
    Summary,
    AnalyticsNumbers,
    Users,
    Teams,
    Reports,
    Something2,
    Voicemail,
    AllExtensions,
  },
  computed: {
    ...mapGetters([
      'getCurrentUser',
      'getIsMobile',
      'getUserSettings',
      'getAdminSettings',
      'getAnalyticSettings',
      GET_LOCAL_SETTING_MOBILE_SETTINGS
    ]),
    tabs(){
      const user = this.getUserSettings
      const admin = this.getAdminSettings
      const analytics = this.getAnalyticSettings
      return {
        user: user,
        admin: admin,
        analytics: analytics,
      }
    },
    keepAliveTabs(){
      const user = this.tabs.user
      const admin = this.tabs.admin
      const keep_alive_user_tabs = user.filter(item=>item.isKeepAlive)
      const keep_alive_admin_tabs = admin.filter(item=>item.isKeepAlive)
      return [...keep_alive_user_tabs,...keep_alive_admin_tabs].map(item=>item.component)
    },
  },
  methods: {
    ...mapMutations([
      SET_LOCAL_SETTING_MOBILE_SETTINGS_TAB
    ]),
  },
  activated(){
    if(this.$store.state.global_conditions.is_mobile_setting){
      this.SET_LOCAL_SETTING_MOBILE_SETTINGS_TAB()
    } else {
      const tab = this.GET_LOCAL_SETTING_MOBILE_SETTINGS.tab || 'Numbers'
      const is_admin_tab = this.tabs.admin.find(i=>i.component==tab)
      const is_user_tab = this.tabs.user.find(i=>i.component==tab)
      this.SET_LOCAL_SETTING_MOBILE_SETTINGS_TAB((is_admin_tab && !this.getCurrentUser?.administrator_account) || (!is_user_tab && !is_admin_tab) ? 'Numbers' : tab)
    }
  },
}
</script>

<style>

</style>
