<template>
  <div>
    <div class="w-100 todo-activity-item">
      <div class="todo-activity-item-topSection">
        <div class="d-flex align-items-center">
          <vb-avatar :is_blf="false" :id="payload.created_by" class="mr-3" />
          <div class="TODO-comment-name">
            {{ getVoipUsersAlisesDisplayName[payload.created_by] }}
          </div>
        </div>
        <div class="todo-comment-Text">
          <template v-if="activity.action==types.create_task">
            Created this task
          </template>
          <template v-else-if="activity.action==types.update_task">
            update task 
          </template>
          <template v-else-if="activity.action==types.assigned_task">
            assigned to 
            <Info class="ml-2" :only_name="true" :is_blf="false" :id="payload.next" />
          </template>
          <template v-else-if="activity.action==types.add_day">
            added to my day
          </template>
          <template v-else-if="activity.action==types.add_attachment">
            added an attachment
          </template>
          <template v-else-if="activity.action==types.remove_attachment">
            remove an attachment
          </template>
          <template v-else-if="activity.action==types.create_checklist">
            created a checklist
          </template>
          <template v-else-if="activity.action==types.create_comment">
            commented
          </template>
          <template v-else-if="activity.action==types.update_cdr_data">
            linked a call
          </template>
          <template v-else-if="activity.action==types.delete_checklist">
            deleted a checklist
          </template>
          <template v-else-if="activity.action==types.start_timer">
            start the timer
          </template>
          <template v-else-if="activity.action==types.stop_timer">
            stop the timer {{ payload.seconds }}s
          </template>
          <template v-else-if="activity.action==types.update_checklist">
            <!-- update the check list -->
            update the check list item to "{{payload.data.description.next}}"
          </template>
          <template v-else-if="activity.action==types.update_task_notes">
            update the notes
          </template>
          <template v-else-if="activity.action==types.task_link">
            task link
          </template>
          <template v-else-if="activity.action==types.task_unlink">
            unlink the task
          </template>
          <template v-else-if="activity.action==types.user_assign_checklist">
            <!-- assign user to check list -->
            assigned check list "{{payload.description}}" to  <b class="ml-2"> {{getVoipUsersAlisesDisplayName[payload.next_id]}}</b>
          </template>
          <template v-else-if="activity.action==types.unassigned_task">
            un assigned the task
          </template>
          <template v-else-if="activity.action==types.assigned_viewer">
            assigned <b class="mx-2"> {{getVoipUsersAlisesDisplayName[payload.next]}}</b> as task viewer 
          </template>
        </div>
      </div>
      <div class="todo-activity-item-bottomSection">
        <div class="TODO-comment-commentedTime ml-2">{{ activity.created_at | filter_date_current(null,{ formate_now: true }) }} at {{ activity.created_at | filter_date_current('',{ formate: 'hh:mm a , DD MMM YYYY' }) }}</div>
      </div>
    </div>
    <!--<div class="d-flex align-items-start mt-24px w-100 TODO-comment-log-row">
      <vb-avatar :is_blf="false" :id="payload.created_by" class="mr-3" />
      <div class="d-flex flex-column align-items-start w-100">
        <div class="d-flex justify-content-between align-items-center w-100">
          <div class="TODO-comment-name">
            {{ getVoipUsersAlisesDisplayName[payload.created_by] }}
            <div class="TODO-comment-commentedTime ml-2">{{ activity.created_at | filter_date_current(null,{ formate_now: true }) }}</div>
          </div>
          <div class="TODO-comment-commentedTime showOnHover ml-2">{{ activity.created_at | filter_date_current('',{ formate: 'hh:mm a , DD MMM YYYY' }) }}</div>
        </div>
        <div class="todo-comment-smallGreyBox">
          <template v-if="activity.action==types.create_task">
            Created this task
          </template>
          <template v-else-if="activity.action==types.update_task">
            update task 
          </template>
          <template v-else-if="activity.action==types.assigned_task">
            assigned to 
            <Info class="ml-2" :only_name="true" :is_blf="false" :id="payload.next" />
          </template>
          <template v-else-if="activity.action==types.add_day">
            added to my day
          </template>
          <template v-else-if="activity.action==types.add_attachment">
            added an attachment
          </template>
          <template v-else-if="activity.action==types.remove_attachment">
            remove an attachment
          </template>
          <template v-else-if="activity.action==types.create_checklist">
            created a checklist
          </template>
          <template v-else-if="activity.action==types.create_comment">
            commented
          </template>
          <template v-else-if="activity.action==types.update_cdr_data">
            linked a call
          </template>
          <template v-else-if="activity.action==types.delete_checklist">
            deleted a checklist
          </template>
          <template v-else-if="activity.action==types.start_timer">
            start the timer
          </template>
          <template v-else-if="activity.action==types.stop_timer">
            stop the timer {{ payload.seconds }}s
          </template>
          <template v-else-if="activity.action==types.update_checklist">
            <!~~ update the check list ~~>
            update the check list item to "{{payload.data.description.next}}"
          </template>
          <template v-else-if="activity.action==types.update_task_notes">
            update the notes
          </template>
          <template v-else-if="activity.action==types.task_link">
            task link
          </template>
          <template v-else-if="activity.action==types.task_unlink">
            unlink the task
          </template>
          <template v-else-if="activity.action==types.user_assign_checklist">
            <!~~ assign user to check list ~~>
            assigned check list "{{payload.description}}" to  <b class="ml-2"> {{getVoipUsersAlisesDisplayName[payload.next_id]}}</b>
          </template>
          <template v-else-if="activity.action==types.unassigned_task">
            un assigned the task
          </template>
          <template v-else-if="activity.action==types.assigned_viewer">
            assigned <b class="mx-2"> {{getVoipUsersAlisesDisplayName[payload.next]}}</b> as task viewer 
          </template>
        </div>
      </div>
    </div>-->
  </div>
</template>

<script>
import Info from '@/components/Lists/Info.vue'
import { mapGetters } from 'vuex'
const types = {
  assigned_task: 'assigned_task',
  update_task: 'update_task',
  create_task: 'create_task',
  assigned_viewer: 'assigned_viewer',
  add_day: 'add_day',
  add_attachment: 'add_attachment',
  remove_attachment: 'remove_attachment',
  create_checklist: 'create_checklist',
  create_comment: 'create_comment',
  update_cdr_data: 'update_cdr_data',
  delete_checklist: 'delete_checklist',
  start_timer: 'Start the timer.',
  stop_timer: 'Stop the timer.',
  update_checklist: 'update_checklist',
  update_task_notes: 'update_task_notes',
  task_link: 'task_link',
  task_unlink: 'task_unlink',
  user_assign_checklist: 'user_assign_checklist',
  unassigned_task: 'unassigned_task',
}
export default {
  name: 'TaskActivityItem',
  components: { 
    Info 
  },
  props: {
    activity: {
      type: Object,
      default: ()=>({})
    },
  },
  computed: {
    ...mapGetters([
      'getVoipUsersAlisesDisplayName'
    ]),
    payload(){
      return JSON.parse(this.activity.payload)
    },
    types(){ return types },
  },
}
</script>

<style>

</style>