<template>
  <component
    :is="component"
    :class="{
      'visibleTransition': isVisible,
    }"
    v-observe-visibility="{
      callback: visibilityChanged,
      intersection: {
        threshold: 1,
      },
    }"
    :call="call"
    :assignedData="assignedData"
    :tags_note_show="tags_note_show"
    :type="type"
    :conditions="conditions"
    @calling="calling($event)"
  >

  </component>
</template>

<script>
import SipCallLog from './SipCallLog.vue'
import JitsiCallLog from './JitsiCallLog.vue'
import VoicemailLog from './VoicemailLog.vue'
import SMSLog from './SMSLog.vue'
import { events } from '@/utils'
import { number_formater } from '@/filter'
export default {
  name: "CallActivityTestItem",
  components: {
    SipCallLog,
    JitsiCallLog,
    VoicemailLog,
    SMSLog,
  },
  props: {
    call: {
      type: Object,
      default: () => ({}),
      required: true,
    },
    assignedData: {
      type: Object,
    },
    tags_note_show: {
      type: Boolean,
      default: true,
    },
    type: {
      type: String,
      default: 'activity',
    },
    conditions: {
      type: Object,
      default: () => ({})
    },
  },
  data() {
    return {
      isVisible: false
    }
  },
  computed: {
    component() {
      if(this.call.call_type=='audio') return 'SipCallLog'
      else if(this.call.call_type=='video') return 'JitsiCallLog'
      else if(this.call.call_type=='voicemail') return 'VoicemailLog'
      else if(this.call.call_type=='sms') return 'SMSLog'
      return '' 
    }
  },
  methods: {
    visibilityChanged (isVisible) { // , entry
      this.isVisible = isVisible
    },
    calling(event) {
      const { dialable, calling_type='video' } = event
      const call_type = this.call.call_type
      if(dialable) return;
      if (call_type=='video') {
        const accounts = typeof dialable === "string" ? [dialable] : dialable;
        this.$root.$emit(events.video_call, { 
          accounts, 
          call_type: calling_type ?? 'audio' 
        });
      } else {
        const number = number_formater(dialable)
        this.$root.$emit(events.audio_call, { 
          number: number 
        });
      }
    },
  },
};
</script>

<style>
</style>