var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    ref: "PowerDialerContainer",
    staticClass: "PowerDialerContainer"
  }, [_c('h2', {
    staticClass: "d-flex flex-wrap align-items-center justify-content-between"
  }, [_vm.selected.sheet_id ? _c('div', {
    staticClass: "goToPreviousScreen"
  }, [_c('vb-svg', {
    staticClass: "cursor_pointer_no_hover",
    attrs: {
      "name": "leftLongArrow-icon",
      "width": "20",
      "height": "12",
      "viewBox": "0 0 30 16",
      "stroke-width": "1",
      "stroke": "",
      "fill": "none",
      "stroke-linecap": "round",
      "stroke-linejoin": "round"
    },
    on: {
      "click": function ($event) {
        _vm.selected.sheet_id = '';
        _vm.response.numbers = [];
      }
    }
  })], 1) : _vm._e(), _c('span', {
    staticClass: "d-block title"
  }, [_vm._v("Power dialer")]), _vm.selected.sheet_id ? _c('div', [_vm.$store.state.power_dialer.start_sheet_id == _vm.selected.sheet_id ? _c('div', {
    staticClass: "startStopDialing",
    on: {
      "click": function ($event) {
        _vm.$store.state.power_dialer.start_sheet_id = '';
      }
    }
  }, [_c('vb-icon', {
    attrs: {
      "icon": "PowerDialer-stop-icon",
      "width": "14px",
      "height": "14px"
    }
  }), _c('div', {
    staticClass: "startStopDialing-text"
  }, [_vm._v("Stop")])], 1) : _c('div', {
    staticClass: "startStopDialing",
    on: {
      "click": function ($event) {
        _vm.$store.state.power_dialer.start_sheet_id ? '' : _vm.$store.state.power_dialer.start_sheet_id = _vm.selected.sheet_id;
      }
    }
  }, [_c('vb-icon', {
    attrs: {
      "icon": "PowerDialer-start-icon",
      "width": "11.79px",
      "height": "13.482px"
    }
  }), _c('div', {
    staticClass: "startStopDialing-text"
  }, [_vm._v("Auto")])], 1)]) : _vm._e()]), !_vm.selected.sheet_id ? _c('div', {
    staticClass: "PowerDialerContainer-inner"
  }, [_vm._l(_vm.response.sheets, function (sheet) {
    return _c('div', {
      key: sheet.id,
      staticClass: "PowerDialer-row",
      staticStyle: {
        "cursor": "pointer"
      },
      on: {
        "click": function ($event) {
          return _vm.selectSheet(sheet);
        }
      }
    }, [_c('div', {
      staticClass: "PowerDialer-row-leftSide"
    }, [_c('vb-icon', {
      staticClass: "PowerDialer-contact-icon",
      attrs: {
        "icon": "PowerDialer-contact-icon"
      }
    }), _c('div', [_c('div', {
      staticClass: "PowerDialer-row-mainText"
    }, [_vm._v(_vm._s(sheet.name))])])], 1), _c('div', {
      staticClass: "PowerDialer-row-rightSide"
    }, [_c('vb-icon', {
      staticClass: "PowerDialer-rightArrow-icon",
      attrs: {
        "icon": "PowerDialer-rightArrow-icon",
        "width": "8.526",
        "height": "14.113"
      }
    })], 1)]);
  }), _vm.api.fetch_sheets.send ? _vm._l(5, function (n) {
    return _c('div', {
      key: n.id,
      staticClass: "PowerDialer-row"
    }, [_c('div', {
      staticClass: "PowerDialer-row-leftSide",
      staticStyle: {
        "width": "calc(100% - 40px)"
      }
    }, [_c('vb-icon', {
      staticClass: "PowerDialer-contact-icon",
      attrs: {
        "icon": "PowerDialer-contact-icon"
      }
    }), _vm._m(0, true)], 1), _c('div', {
      staticClass: "PowerDialer-row-rightSide"
    }, [_c('vb-icon', {
      staticClass: "PowerDialer-phone-icon",
      attrs: {
        "icon": "PowerDialer-phone-icon",
        "width": "15.983px",
        "height": "16.002px"
      }
    })], 1)]);
  }) : _vm.isEmpty(_vm.response.sheets) ? _c('div', {
    staticClass: "emptyScreenContainer mt-32px b-0 p-0"
  }, [_vm.GET_LOCAL_SETTING_IS_DARK_MODE ? _c('img', {
    attrs: {
      "width": "50%",
      "height": "auto",
      "src": require('@/assets/images/emptyScreenImages/darkMode/addressBook.png')
    }
  }) : _c('img', {
    attrs: {
      "width": "50%",
      "height": "auto",
      "src": require('@/assets/images/emptyScreenImages/addressBook.png')
    }
  }), _c('div', {
    staticClass: "emptyScreenContainer-heading mt-32px"
  }, [_vm._v("No record")])]) : _vm._e()], 2) : _c('div', {
    staticClass: "PowerDialerContainer-inner"
  }, [_vm._l(_vm.response.numbers, function (number) {
    return _c('div', {
      key: number.id,
      staticClass: "PowerDialer-row"
    }, [_c('div', {
      staticClass: "PowerDialer-row-leftSide"
    }, [_c('vb-icon', {
      staticClass: "PowerDialer-contact-icon",
      attrs: {
        "icon": "PowerDialer-contact-icon"
      }
    }), _c('div', [_c('div', {
      directives: [{
        name: "b-tooltip",
        rawName: "v-b-tooltip",
        value: number.name,
        expression: "number.name"
      }],
      staticClass: "PowerDialer-row-mainText name-chip"
    }, [_vm._v(_vm._s(number.name))]), _c('div', {
      staticClass: "PowerDialer-row-secondaryText"
    }, [_vm._v(_vm._s(_vm._f("number_formater")(number.number)))]), number.state ? _c('div', {
      staticClass: "PowerDialer-row-verySmallText"
    }, [_c('span', [_vm._v(_vm._s(number.state))]), number.state == 'dialing' ? _c('div', {
      staticClass: "d-flex"
    }, [number.dialed_by != _vm.getCurrentUser.account ? _c('div', {
      staticClass: "d-flex mr-2"
    }, [_c('span', {
      staticClass: "mr-1"
    }, [_vm._v("by")]), number.dialed_by ? _c('Info', {
      attrs: {
        "id": number.dialed_by,
        "only_name": true
      }
    }) : _vm._e()], 1) : _vm._e(), _vm._m(1, true)]) : _vm._e()]) : _vm._e()])], 1), _c('div', {
      staticClass: "PowerDialer-row-rightSide"
    }, [_c('vb-icon', {
      staticClass: "PowerDialer-phone-icon",
      class: `${number.state == 'dialing' || _vm.$store.state.power_dialer.start_sheet_id ? 'disabled' : ''}`,
      attrs: {
        "icon": "PowerDialer-phone-icon",
        "width": "15.983px",
        "height": "16.002px"
      },
      on: {
        "click": function ($event) {
          _vm.$store.state.power_dialer.start_sheet_id || number.state == 'dialing' ? '' : _vm.dialPowerDialerNumber(number);
        }
      }
    })], 1)]);
  }), _vm.api.fetch_sheet_numbers.send ? _vm._l(5, function (n) {
    return _c('div', {
      key: n.id,
      staticClass: "PowerDialer-row"
    }, [_c('div', {
      staticClass: "PowerDialer-row-leftSide",
      staticStyle: {
        "width": "calc(100% - 40px)"
      }
    }, [_c('vb-icon', {
      staticClass: "PowerDialer-contact-icon",
      attrs: {
        "icon": "PowerDialer-contact-icon"
      }
    }), _vm._m(2, true)], 1), _c('div', {
      staticClass: "PowerDialer-row-rightSide"
    }, [_c('vb-icon', {
      staticClass: "PowerDialer-phone-icon",
      attrs: {
        "icon": "PowerDialer-phone-icon",
        "width": "15.983px",
        "height": "16.002px"
      }
    })], 1)]);
  }) : _vm.isEmpty(_vm.response.numbers) ? _c('div', {
    staticClass: "emptyScreenContainer mt-32px b-0 p-0"
  }, [_vm.GET_LOCAL_SETTING_IS_DARK_MODE ? _c('img', {
    attrs: {
      "width": "50%",
      "height": "auto",
      "src": require('@/assets/images/emptyScreenImages/darkMode/addressBook.png')
    }
  }) : _c('img', {
    attrs: {
      "width": "50%",
      "height": "auto",
      "src": require('@/assets/images/emptyScreenImages/addressBook.png')
    }
  }), _c('div', {
    staticClass: "emptyScreenContainer-heading mt-32px"
  }, [_vm._v("No record")])]) : _vm._e()], 2)]);

}
var staticRenderFns = [function (){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticStyle: {
      "width": "calc(100% - 40px)"
    }
  }, [_c('div', {
    staticClass: "PowerDialer-row-mainText name-chip w-100 mb-1"
  }, [_c('div', {
    staticClass: "latestShimmerDesign",
    staticStyle: {
      "height": "15px",
      "width": "100%"
    }
  })]), _c('div', {
    staticClass: "PowerDialer-row-secondaryText w-100"
  }, [_c('div', {
    staticClass: "latestShimmerDesign",
    staticStyle: {
      "height": "13px",
      "width": "100%"
    }
  })])]);
} /* staticRenderFnsSpliter */, function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "dialingCircles"
  }, [_c('div', {
    staticClass: "dialingCircle"
  }), _c('div', {
    staticClass: "dialingCircle"
  }), _c('div', {
    staticClass: "dialingCircle"
  })]);
} /* staticRenderFnsSpliter */, function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticStyle: {
      "width": "calc(100% - 40px)"
    }
  }, [_c('div', {
    staticClass: "PowerDialer-row-mainText name-chip w-100 mb-1"
  }, [_c('div', {
    staticClass: "latestShimmerDesign",
    staticStyle: {
      "height": "15px",
      "width": "100%"
    }
  })]), _c('div', {
    staticClass: "PowerDialer-row-secondaryText w-100"
  }, [_c('div', {
    staticClass: "latestShimmerDesign",
    staticStyle: {
      "height": "13px",
      "width": "100%"
    }
  })])]);

}]

export { render, staticRenderFns }