var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c(_vm.component, {
    directives: [{
      name: "observe-visibility",
      rawName: "v-observe-visibility",
      value: {
        callback: _vm.visibilityChanged,
        intersection: {
          threshold: 1
        }
      },
      expression: "{\n    callback: visibilityChanged,\n    intersection: {\n      threshold: 1,\n    },\n  }"
    }],
    tag: "component",
    class: {
      'visibleTransition': _vm.isVisible
    },
    attrs: {
      "call": _vm.call,
      "assignedData": _vm.assignedData,
      "tags_note_show": _vm.tags_note_show,
      "type": _vm.type,
      "conditions": _vm.conditions
    },
    on: {
      "calling": function ($event) {
        return _vm.calling($event);
      }
    }
  });

}
var staticRenderFns = []

export { render, staticRenderFns }