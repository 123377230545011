<template>
  <div 
    class="callActivity-Item-v2-container"
    :class="[
      call.status=='Dialed' ? 'Right' : 'Left',
      {
        'missedCall': call.status=='Missed',
        'recievedCall': call.status=='Received',
        'dialledCall': call.status=='Dialed'
      }
    ]" 
  >
    <div class="callActivity-Item-v2-inner-top">
      <div class="icon-container">
        <vb-icon v-if="call.status=='Missed'" v-b-popover.hover.top="'Missed Video Call'" icon="CallActivityV2-missedVideoCall-icon" height="55.922" width="55.922" />
        <vb-icon v-else-if="call.status=='Received'" v-b-popover.hover.top="'Received Video Call'" icon="CallActivityV2-receivedVideoCall-icon" height="55.922" width="55.922" />
        <vb-icon v-else v-b-popover.hover.top="'Dialed Video Call'" icon="CallActivityV2-dialledVideoCall-icon" height="55.922" width="55.922" />
      </div>
      <div class="user-info">
        <div class="user-name">{{ call.destinationName | number_formater }}</div>
        <div v-if="call.destinationExt" class="user-number">{{ call.destinationExt | number_formater }}</div>
      </div>
      <div class="middle-section">
        <span class="middle-section-text">
          
        </span>
      </div>
      <div class="callLogIcons-container">
        <template v-if="condition.actions && !assignedData">
          <!-- call -->
          <span class="mr-8px">
            <vb-icon v-b-tooltip.hover title="Audio call" icon="call-callLog-icon" width="35px" height="35px" @click="$emit('calling')"/>
          </span>
          <!-- video call -->
          <span class="mr-8px">
            <vb-icon v-b-tooltip.hover title="Video call" icon="videoCall-callLog-icon" width="35px" height="35px" @click="$emit('calling')" />
          </span>
          <!-- call info -->
          <span class="mr-8px">
            <vb-icon icon="callInfo-callLog-icon" width="35px" height="35px" @click="$emit('show-modal')" v-b-tooltip.hover :title="`Call info`" />
          </span>
        </template>
      </div>
      <div class="last-section">
        <div class="time">
          <span>{{ call.date | filter_date_current('h:mm a',{ unix: true }) }}</span>
          <slot name="more">
            <b-dropdown v-if="condition.actions" varient="link" no-caret class="ico-filter" menu-class="w-fit-content-imp">
              <template v-slot:button-content>
                <b-icon icon="three-dots" variant="#393939" font-scale="1.4"/>
              </template>
              <template v-if="!assignedData">
                <b-dropdown-item @click="$emit('calling')">
                  <b-icon icon="telephone" font-scale="0.96"/>
                  <span class="ml-3">Call</span>
                </b-dropdown-item>
                <b-dropdown-item @click="$emit('calling')">
                  <b-icon icon="telephone" font-scale="0.96"/>
                  <span class="ml-3">Video call</span>
                </b-dropdown-item>
              </template>
              <b-dropdown-item v-if="!assignedData" @click="$emit('show-modal')">
                <vb-icon icon="dialer-callinfo-icon" width="15.7" height="15.701" class="callInfoIcon" />
                <span class="ml-3">Call info</span>
              </b-dropdown-item>
            </b-dropdown>
          </slot>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import { GET_LOCAL_SETTING_DASHBOARD } from '@/store/helper/getters';
export default {
  name: "CallActivityNewItem",
  props: {
    call: {
      type: Object,
      default: () => ({}),
      required: true,
    },
    assignedData: {
      type: Object,
    },
    tags_note_show: {
      type: Boolean,
      default: true,
    },
    type: {
      type: String,
      default: 'activity',
    },
    conditions: {
      type: Object,
      default: () => ({})
    },
  },
  computed: {
    ...mapGetters([
      'getVoipCallTags',
      'getCurrentUser',
      'getIsMobile',
      GET_LOCAL_SETTING_DASHBOARD
    ]),
  },
};
</script>

<style>
</style>