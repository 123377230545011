<template>
  <div>
    <modal :class="`AddListModal ${conditions.table_show ? 'tableIsVisible' : ''}`" :name="modalName" @before-open="onBeforeOpen($event)" @before-close="onBeforeClose()">
      <div class="dialer-edit-header pt-0 d-block">
        <div class="d-flex justify-content-between align-items-center w-100">
          <h2 class="dialer-settings-title newer mb-0">Create List </h2>
          <div class="dialer-edit-actions">
            <b-icon v-if="getIsMobile" class="cursor_pointer" icon="x" font-scale="1.8" @click="$modal.hide(modalName);" />
            <a id="CloseModalButton" v-else class="newCloseButton" @click="$modal.hide(modalName);">
              <vb-icon icon="squared-close-icon" height="38px" width="38px" />
              <span class="newCloseButton-textPart">Close</span>
            </a>
          </div>
        </div>
      </div>
      <div v-if="conditions.table_show">
        <ul class="list-unstyled mt-20px">
          <li>
            <b-table 
              show-empty
              responsive
              sticky-header="700px"
              :fields="file_data.columns"
              :items="after_filter_file_data.data"
              :busy="api.create_sheet.send"
            >
              <template #empty>
                <div class="d-flex justify-content-center align-items-center flex-column w-100 my-2">
                  <vb-icon icon="noDataFound-v2" width="57" height="63" />
                  <div class="mt-10px latestGreyBox-heading-main-18px" >
                    No data found
                  </div>
                </div>
              </template>
              <template #table-busy>
                <div class="w-100 py-2" v-for="n in 5" :key="n.id">
                  <div class="latestShimmerDesign" style="height: 24px;width: 100%;"></div>
                </div>
              </template>
            </b-table>
          </li>
          <li>
            <div class="d-flex">
              <button v-if="!$v.forms.add_sheet.$invalid" @click="conditions.table_show=false" class="fullWidthCloseButton w-50 mr-3">No</button>
              <button v-if="!$v.forms.add_sheet.$invalid" @click="upload()" class="fullWidthDoneButton w-50 ml-3">Yes</button>
            </div>
          </li>
        </ul>
      </div>
      <div v-else> 
        <div class="latestGreyBox-9-9-2023 mt-20px">
          <!-- name -->
          <div class="whiteBGinputWithGreyRoundedBorder dropdownVersion w-100 mb-20px">
            <label>List name</label>
            <b-input type="text" class="w-100" v-model="forms.add_sheet.name" placeholder="Enter list name" />
            <p class="mb-1 text-small text text-danger animated bounceIntop" v-if="$v.forms.add_sheet.name.$dirty && $v.forms.add_sheet.name.$invalid">
              <span v-if="!$v.forms.add_sheet.name.required">Name is required</span>
            </p>
          </div>
          <!-- assign to -->
          <div class="whiteBoxWithBorderInsideGreyBox mb-20px">
            <div class="whiteBoxWithBorderInsideGreyBox-row">
              <div>
                <div class="latestGreyBox-heading-main">Assign to</div>
                <div class="latestGreyBox-descriptionText">
                  {{forms.add_sheet.assign_to.length==0?'Select User or Team' : `${forms.add_sheet.assign_to.length} extensions`}}
                </div>
                <p class="mb-1 text-small text text-danger animated bounceIntop mt-10px" v-if="$v.forms.add_sheet.assign_to.$dirty && $v.forms.add_sheet.assign_to.$invalid">
                  <span v-if="!$v.forms.add_sheet.assign_to.required">Assign to is required</span>
                </p>
              </div>
              <button class="newButton sm-mar-left" 
                type="button" 
                @click="$modal.show(`${_uid}-SelectExtensions`,{
                  accountcodes: forms.add_sheet.assign_to,
                })"
              >
                <vb-icon icon="squared-add-icon" height="38px" width="38px" />
                <span class="newButton-textPart">Assign</span>
              </button>
            </div>
          </div>
          <!-- upload CSV -->
          <div class="latestGreyBox-heading-main mt-20px mb-10px">Upload a CSV</div>
          <input type="file" @change="validateFile(getProperty($event,'target.files[0]',null))" :ref="`${_uid}-file_upload_csv`" accept=".csv" style="display: none;"/>
          <div class="uploadSoundFileDesign-latest py-3 position-relative mb-20px" @dragenter.prevent="conditions.drag_and_drop=true">
            <div class="w-100 h-100 position-absolute total-centring d-flex justify-content-center align-items-center text-center"
              v-if="conditions.drag_and_drop" 
              @dragenter.prevent="conditions.drag_and_drop=true" 
              @dragover.prevent="conditions.drag_and_drop=true" 
              @dragleave.prevent="conditions.drag_and_drop=false" 
              @drop.prevent="conditions.drag_and_drop=false;validateFile(getProperty($event,'dataTransfer.files[0]',null))" 
            >
              <b>Drag and drop</b>
            </div>
            <div v-else>
              <div v-if="forms.add_sheet.csv_file" class="position-relative d-flex flex-column align-items-center w-100  justify-content-center">
                <vb-icon icon="CSV-powerDialer-icon" height="100px" width="100px" class="mb-20px" />
                <div class="uploadSoundFileDesign-text mt-10px">
                  <b>
                    <span>{{forms.add_sheet.csv_file.name}}</span>
                  </b>
                </div>
                <b-button @click="
                  forms.add_sheet.csv_file=null;
                  forms.add_sheet.name_column='';
                  forms.add_sheet.number_column='';
                  $refs[`${_uid}-file_upload_csv`].value='';
                  file_data.data={};
                  file_data.columns=[];
                " variant="link" class="topRightCornerAbsoluteButtonIcon">
                  <b-icon variant="danger" icon="x" />
                </b-button>
              </div>
              <div v-else>
                <div class="w-100 d-flex justify-content-center">
                  <vb-icon icon="PowerDialer-upload-icon" width="48.68px" height="39.645px" />
                </div>
                <div class="uploadSoundFileDesign-heading">
                  Select a <span class="bluer" @click="$refs[`${_uid}-file_upload_csv`].click()" style="text-decoration: none; cursor: pointer;">CSV</span> file to upload
                </div>
                <div class="uploadSoundFileDesign-text mt-10px">
                  <b>or drag and drop it here</b>
                </div>
              </div>
            </div>
          </div>
          <p class="mb-1 text-small text text-danger animated bounceIntop" v-if="$v.forms.add_sheet.csv_file.$dirty && $v.forms.add_sheet.csv_file.$invalid">
            <span v-if="!$v.forms.add_sheet.csv_file.required">File is required</span>
          </p>
          <!-- number column -->
          <div v-if="forms.add_sheet.csv_file" class="whiteBGinputWithGreyRoundedBorder dropdownVersion w-100 mb-20px">
            <label>Number column</label>
            <vb-select 
              class="w-100"
              v-model="forms.add_sheet.number_column"
              :options="file_data.columns"
            />
            <p class="mb-1 text-small text text-danger animated bounceIntop" v-if="$v.forms.add_sheet.number_column.$dirty && $v.forms.add_sheet.number_column.$invalid">
              <span v-if="!$v.forms.add_sheet.number_column.required">Number column is required</span>
            </p>
          </div>
          <!-- name column -->
          <div v-if="forms.add_sheet.csv_file" class="whiteBGinputWithGreyRoundedBorder dropdownVersion w-100">
            <label>Name column</label>
            <vb-select 
              class="w-100"
              v-model="forms.add_sheet.name_column"
              :options="file_data.columns"
            />
            <p class="mb-1 text-small text text-danger animated bounceIntop" v-if="$v.forms.add_sheet.name_column.$dirty && $v.forms.add_sheet.name_column.$invalid">
              <span v-if="!$v.forms.add_sheet.name_column.required">Name column is required</span>
            </p>
          </div>
        </div>
        <button @click="$v.forms.add_sheet.$touch();validatePhoneNumber();" class="fullWidthDoneButton mt-20px">
          <template>Next</template>
        </button>
      </div>
    </modal>
    <SelectExtensionsModal 
      :modalName="`${_uid}-SelectExtensions`" 
      :extensionTypes="['USER','TEAM']"
      :allowMultiSelect="true"
      @update="forms.add_sheet.assign_to=$event.selected.accountcodes" 
    />
    <modal :name="`${_uid}-InvalidNumbers`" class="AddListModal-InvalidNumbers-modal tableIsVisible" >
      <div class="dialer-edit-header pt-0 d-block mb-20px">
        <div class="d-flex justify-content-between align-items-center w-100">
          <h2 class="dialer-settings-title newer mb-0">Removed items</h2>
          <div class="dialer-edit-actions">
            <b-icon v-if="getIsMobile" class="cursor_pointer" icon="x" font-scale="1.8" @click="$modal.hide(`${_uid}-InvalidNumbers`);" />
            <button v-else class="newDoneButton" @click="$modal.hide(`${_uid}-InvalidNumbers`);">
              <vb-icon icon="squared-done-icon" height="38px" width="38px" />
              <span class="newDoneButton-textPart">Done</span>
            </button>
          </div>
        </div>
      </div>
      <b-table
        responsive
        sticky-header="700px"
        :items="after_filter_file_data.removed"
      >
      </b-table>
    </modal>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import required from 'vuelidate/lib/validators/required'
import { validNumberChecker } from '@/filter'
import * as XLSX from "xlsx";
import { VOIP_API } from '@/utils'
import Papa from 'papaparse';
import SelectExtensionsModal from '../Modals/SelectExtensions.vue'
export default {
  name: 'AddListModal',
  components: {
    SelectExtensionsModal,
  },
  inject: [
    'getProperty',
    'appNotify',
  ],
  props: {
    modalName: {
      type: String,
      default: 'AddListModal'
    },
  },
  data() {
    return {
      forms: {
        add_sheet: this.$helperFunction.formInstance({
          data: {
            name: '',
            assign_to: [],
            csv_file: null,
            number_column: '',
            name_column: '',
          },
        }),
      },
      api: {
        create_sheet: this.$helperFunction.apiInstance({}),
        read_file: this.$helperFunction.apiInstance({}),
        filter_valid_data: this.$helperFunction.apiInstance({}),
      },
      file_data: {
        columns: [],
        data: [],
      },
      after_filter_file_data: {
        data: [],
        removed: [],
      },
      conditions: {
        drag_and_drop: false,
        table_show: false,
      },
    }
  },
  validations: {
    forms: {
      add_sheet: {
        name: {
          required: required,
        },
        assign_to: {
          required: required,
        },
        csv_file: {
          required: required,
        },
        number_column: {
          required: required,
        },
        name_column: {
          required: required,
        },
      },
    },
  },
  computed: {
    ...mapGetters([
      'getIsMobile',
    ]),
  },
  methods: {
    onBeforeOpen(){
      // TODO - 
    },
    onBeforeClose(){
      this.$v.forms.add_sheet.$reset()
      this.conditions.drag_and_drop=false
      this.conditions.table_show=false
      this.file_data.columns=[]
      this.file_data.data=[]
      this.after_filter_file_data.data=[]
      this.after_filter_file_data.removed=[]
      this.forms.add_sheet.reset()
      this.api.create_sheet.reset()
      this.api.read_file.reset()
      this.api.filter_valid_data.reset()
    },
    validateFile(file) {
      let vm = this
      if(vm.api.read_file.send) return;
      if(file.type!='text/csv') {
        this.appNotify({
          message: 'File type must be text/csv',
          type: 'danger',
        })
        return;
      }
      vm.api.read_file.send=true
      Papa.parse(file,{
        complete(result) {
          vm.file_data.data=result.data
          vm.file_data.columns=result.meta.fields
          vm.forms.add_sheet.csv_file=file
          vm.api.read_file.send=false
        },
        error(err, file, inputElem, reason){
          vm.appNotify({
            message: reason,
            type: 'danger',
          })
        },
        header: true,
        skipEmptyLines: true,
      })
      this.$refs[`${this._uid}-file_upload_csv`].value=''
    },
    async validatePhoneNumber(){
      if(this.api.filter_valid_data.send || this.$v.forms.add_sheet.$invalid) return;
      try {
        this.api.filter_valid_data.send=true
        let new_data = []
        let invalid_numbers = []
        for (let index = 0; index < this.file_data.data.length; index++) {
          const row = this.file_data.data[index];
          const is_valid_number = validNumberChecker(row[this.forms.add_sheet.number_column])
          if(is_valid_number) new_data.push(row)
          else invalid_numbers.push(row)
        }
        this.after_filter_file_data.data=new_data
        this.after_filter_file_data.removed=invalid_numbers
        this.conditions.table_show=true
        if(invalid_numbers.length>0) {
          this.$modal.show(`${this._uid}-InvalidNumbers`)
        }
      } catch (ex) {
        this.appNotify({
          message: ex.message,
          type: 'danger',
        })
      } finally {
        this.api.filter_valid_data.send=false
      }
    },
    async upload(){
      if(this.api.create_sheet.send) return;
      try {
        this.api.create_sheet.send=true
        const workbook = XLSX.utils.book_new();
        const worksheet = XLSX.utils.json_to_sheet(this.after_filter_file_data.data);
        XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1");
        const excelBinary = XLSX.write(workbook, { bookType: "xlsx", type: "binary" });
        const buf = new ArrayBuffer(excelBinary.length);
        const view = new Uint8Array(buf);
        for (let i = 0; i < excelBinary.length; ++i) view[i] = excelBinary.charCodeAt(i) & 0xFF;
        const excelBlob = new Blob([buf], { type: "application/octet-stream" });
        const formData = new FormData();
        
        formData.append('file', excelBlob, `${this.forms.add_sheet.name}.xlsx`);
        formData.append('assign_to', this.forms.add_sheet.assign_to.join(','));
        formData.append('number_column', this.forms.add_sheet.number_column);
        formData.append('name_column', this.forms.add_sheet.name_column);
        formData.append('name', this.forms.add_sheet.name);
        // const url = window.URL.createObjectURL(excelBlob);
        // const a = document.createElement("a");
        // a.style.display = "none";
        // a.href = url;
        // a.download = `${this.forms.add_sheet.name}.xlsx`;
        // document.body.appendChild(a);
        // a.click();
        // a.remove();
        // window.URL.revokeObjectURL(url);
        await VOIP_API.endpoints.power_dialer.createSheet(formData)
        this.$emit('created')
        this.$modal.hide(this.modalName)
        this.appNotify({
          message: 'Succssfully Created!',
          type: 'success'
        })
      } catch (ex) {
        this.appNotify({
          message: ex.own_message || ex.message,
          type: 'danger'
        })
      } finally {
        this.api.create_sheet.send=false
      }
    },
  },
}
</script>

<style>

</style>